import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Typography,
  Result,
  Table,
  Descriptions,
  Row,
  Col,
  Button,
  Space,
  Form,
  Modal,
  Tooltip,
  Input,
  PaginationProps,
  Checkbox,
} from 'antd';
import { ColumnsType } from 'antd/es/table';

import {
  LetterTemplateBasicFragment,
  EmailTemplateBasicFragment,
  SmsTemplateBasicFragment,
  useSearchLetterTemplatesLazyQuery,
  useSearchEmailTemplatesLazyQuery,
  useSearchSmsTemplatesLazyQuery,
  PracticeDetailsFragment,
  useUpdateEmailTemplateMutation,
  useUpdateLetterTemplateMutation,
  useUpdateSmsTemplateMutation,
  Order_By,
  SearchLetterTemplatesQueryVariables,
  SearchEmailTemplatesQueryVariables,
  SearchSmsTemplatesQueryVariables,
} from '../graphql/generated';
import LetterTemplatePreview from '../components/templates/LetterTemplatePreview';
import EmailTemplatePreview from '../components/templates/EmailTemplatePreview';
import SMSTemplatePreview from '../components/templates/SMSTemplatePreview';

//import { UserMutatationModal } from '../components/users/UserForm';

import { ReactComponent as ColorWheel } from '../images/Colorwheel.svg';
import { ReactComponent as BlackAndWhiteWheel } from '../images/BlackAndWhitewheel.svg';

import {
  MailOutlined,
  MessageOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { PaperPlaneOutlined } from '../components/MabelIcons';
import { EmailTemplateMutationModal } from '../components/templates/EmailTemplateForm';
import { LetterTemplateMutationModal } from '../components/templates/LetterTemplateForm';
import { SMSTemplateMutationModal } from '../components/templates/SMSTemplateForm';
import KeyValueForm, {
  variablesToFormVals,
} from '../components/utils/KeyValueForm';
import useConstant from 'use-constant';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { DEBOUNCE_MS } from '../components/autocompletes';
import { textToHighlight } from '../components/utils/Highlight';

const { Title } = Typography;

const TemplatesAdminPage = () => {
  const initialLetterTemplateQuery = null;
  const initialLetterTemplateSearchParams: {
    variables: SearchLetterTemplatesQueryVariables;
    pagination: PaginationProps;
  } = {
    variables: {
      exact_query: initialLetterTemplateQuery || null,
      substring_query: initialLetterTemplateQuery
        ? `%${initialLetterTemplateQuery}%`
        : null,
      additional_filters: [{ is_archived: { _in: [false] } }],
      offset: 0,
      limit: 100,
      order_by: [{ updated_at: Order_By.Desc }],
    },
    pagination: {
      current: 1,
      pageSize: 100,
    },
  };
  const [searchLetterTemplateParams, setSearchLetterTemplateParams] = useState(
    initialLetterTemplateSearchParams
  );

  const initialEmailTemplateQuery = null;
  const initialEmailTemplateSearchParams: {
    variables: SearchEmailTemplatesQueryVariables;
    pagination: PaginationProps;
  } = {
    variables: {
      exact_query: initialEmailTemplateQuery || null,
      substring_query: initialEmailTemplateQuery
        ? `%${initialEmailTemplateQuery}%`
        : null,
      additional_filters: [{ is_archived: { _in: [false] } }],
      offset: 0,
      limit: 100,
      order_by: [{ updated_at: Order_By.Desc }],
    },
    pagination: {
      current: 1,
      pageSize: 100,
    },
  };
  const [searchEmailTemplateParams, setSearchEmailTemplateParams] = useState(
    initialEmailTemplateSearchParams
  );

  const initialSMSTemplateQuery = null;
  const initialSMSTemplateSearchParams: {
    variables: SearchSmsTemplatesQueryVariables;
    pagination: PaginationProps;
  } = {
    variables: {
      exact_query: initialSMSTemplateQuery || null,
      substring_query: initialSMSTemplateQuery
        ? `%${initialSMSTemplateQuery}%`
        : null,
      additional_filters: [{ is_archived: { _in: [false] } }],
      offset: 0,
      limit: 100,
      order_by: [{ updated_at: Order_By.Desc }],
    },
    pagination: {
      current: 1,
      pageSize: 100,
    },
  };
  const [searchSMSTemplateParams, setSearchSMSTemplateParams] = useState(
    initialSMSTemplateSearchParams
  );

  const [
    searchLetterTemplatesQuery,
    searchLetterTemplatesQueryResult,
  ] = useSearchLetterTemplatesLazyQuery();
  const [
    searchEmailTemplatesQuery,
    searchEmailTemplatesQueryResult,
  ] = useSearchEmailTemplatesLazyQuery();
  const [
    searchSMSTemplatesQuery,
    searchSMSTemplatesQueryResult,
  ] = useSearchSmsTemplatesLazyQuery();

  const searchEmailTemplatesDebounced = useConstant(() =>
    AwesomeDebouncePromise(
      (searchParams: SearchEmailTemplatesQueryVariables) => {
        return searchEmailTemplatesQuery({ variables: searchParams });
      },
      DEBOUNCE_MS,
      { key: (fieldId, _) => fieldId }
    )
  );
  const searchLetterTemplatesDebounced = useConstant(() =>
    AwesomeDebouncePromise(
      (searchParams: SearchLetterTemplatesQueryVariables) => {
        return searchLetterTemplatesQuery({ variables: searchParams });
      },
      DEBOUNCE_MS,
      { key: (fieldId, _) => fieldId }
    )
  );
  const searchSMSTemplatesDebounced = useConstant(() =>
    AwesomeDebouncePromise(
      (searchParams: SearchSmsTemplatesQueryVariables) => {
        return searchSMSTemplatesQuery({ variables: searchParams });
      },
      DEBOUNCE_MS,
      { key: (fieldId, _) => fieldId }
    )
  );

  useEffect(() => {
    searchEmailTemplatesDebounced(searchEmailTemplateParams.variables);
  }, [searchEmailTemplateParams, searchEmailTemplatesDebounced]);
  useEffect(() => {
    searchLetterTemplatesDebounced(searchLetterTemplateParams.variables);
  }, [searchLetterTemplateParams, searchLetterTemplatesDebounced]);
  useEffect(() => {
    searchSMSTemplatesDebounced(searchSMSTemplateParams.variables);
  }, [searchSMSTemplateParams, searchSMSTemplatesDebounced]);

  // const [updateRequestMutation] = useUpdateAccountCreationRequestMutation();
  // const [showCreateModal, setShowCreateModal] = useState(false);
  // const [selectedRecord, setSelectedRecord] = useState<
  //   AccountCreationRequestDetailsFragment | undefined
  // >();
  const [
    showCreateLetterTemplateModal,
    setShowCreateLetterTemplateModal,
  ] = useState(false);
  const [
    showUpdateLetterTemplateModal,
    setShowUpdateLetterTemplateModal,
  ] = useState(false);

  const [selectedLetterTemplate, setSelectedLetterTemplate] = useState<
    LetterTemplateBasicFragment | undefined
  >();

  const [emailMergeVarsForm] = Form.useForm();
  const [emailMergeVarModalVisible, setEmailMergeVarsModalVisible] = useState(
    false
  );

  const [letterMergeVarsForm] = Form.useForm();
  const [letterMergeVarsModalVisible, setLetterMergeVarModalVisible] = useState(
    false
  );

  const [SMSMergeVarsForm] = Form.useForm();
  const [SMSMergeVarsModalVisible, setSMSMergeVarsModalVisible] = useState(
    false
  );

  const [
    updateEmailTemplateMutation,
    updateEmailTemplateMutationResults,
  ] = useUpdateEmailTemplateMutation();

  const [
    updateLetterTemplateMutation,
    updateLetterTemplateMutationResults,
  ] = useUpdateLetterTemplateMutation();

  const [
    updateSMSTemplateMutation,
    updateSMSTemplateMutationResults,
  ] = useUpdateSmsTemplateMutation();

  const [
    showCreateEmailTemplateModal,
    setShowCreateEmailTemplateModal,
  ] = useState(false);
  const [
    showUpdateEmailTemplateModal,
    setShowUpdateEmailTemplateModal,
  ] = useState(false);

  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState<
    EmailTemplateBasicFragment | undefined
  >();

  const [showCreateSMSTemplateModal, setShowCreateSMSTemplateModal] = useState(
    false
  );
  const [showUpdateSMSTemplateModal, setShowUpdateSMSTemplateModal] = useState(
    false
  );

  const [selectedSMSTemplate, setSelectedSMSTemplate] = useState<
    SmsTemplateBasicFragment | undefined
  >();

  if (
    searchLetterTemplatesQueryResult.error ||
    searchEmailTemplatesQueryResult.error ||
    searchSMSTemplatesQueryResult.error
  )
    return (
      <Result
        status="error"
        title="There was an error retrieving the requests"
        subTitle={`${searchLetterTemplatesQueryResult.error?.message} ${searchEmailTemplatesQueryResult.error?.message} ${searchSMSTemplatesQueryResult.error?.message} `}
      />
    );

  const email_columns: ColumnsType<EmailTemplateBasicFragment> = [
    {
      title: 'Name',
      dataIndex: 'email_template_name',
      sorter: true,
      render: textToHighlight(searchEmailTemplateParams.variables.exact_query),
    },
    {
      title: 'From',
      dataIndex: 'email_from',
      sorter: true,
    },
    {
      title: 'Subject Line',
      dataIndex: 'email_subject',
      sorter: true,
    },
    {
      key: 'edit',
      width: 1,
      align: 'center',
      render: (_, record) => (
        <Button
          type="link"
          size="small"
          onClick={() => {
            setSelectedEmailTemplate(record);
            setShowUpdateEmailTemplateModal(true);
          }}
        >
          Edit
        </Button>
      ),
    },
  ];
  const letter_columns: ColumnsType<LetterTemplateBasicFragment> = [
    {
      title: 'Name',
      dataIndex: 'letter_template_name',
      sorter: true,
      render: textToHighlight(searchLetterTemplateParams.variables.exact_query),
    },
    {
      title: 'Format',
      dataIndex: 'format',
      sorter: true,
    },
    {
      title: 'External ID',
      dataIndex: 'external_id',
      sorter: true,
    },
    {
      title: 'External Secondary ID',
      dataIndex: 'external_secondary_id',
      sorter: true,
    },
    {
      title: 'Size',
      dataIndex: 'size',
      sorter: true,
    },
    {
      title: 'Page Count',
      dataIndex: 'num_pages',
      sorter: true,
    },
    {
      title: 'Perforated Page',
      dataIndex: 'perforated_page',
      sorter: true,
    },
    {
      title: 'Color',
      dataIndex: 'is_color',
      width: 1,
      align: 'center',
      sorter: true,
      filters: [
        {
          text: 'B&W',
          value: 'false',
        },
        {
          text: 'Color',
          value: 'true',
        },
      ],
      onFilter: (value, record) => record.is_color.toString() === value,
      render: (is_color) =>
        is_color ? (
          <ColorWheel style={{ width: 20, height: 20 }} />
        ) : (
          <BlackAndWhiteWheel style={{ width: 20, height: 20 }} />
        ),
    },
    {
      key: 'edit',
      width: 1,
      align: 'center',
      render: (_, record) => (
        <Button
          type="link"
          size="small"
          onClick={() => {
            setSelectedLetterTemplate(record);
            setShowUpdateLetterTemplateModal(true);
          }}
        >
          Edit
        </Button>
      ),
    },
  ];

  const sms_columns: ColumnsType<SmsTemplateBasicFragment> = [
    {
      title: 'Name',
      dataIndex: 'sms_template_name',
      sorter: true,
      render: textToHighlight(searchSMSTemplateParams.variables.exact_query),
    },
    {
      title: 'Body Text',
      dataIndex: 'body_text',
      sorter: true,
    },
    {
      key: 'edit',
      width: 1,
      align: 'center',
      render: (_, record) => (
        <Button
          type="link"
          size="small"
          onClick={() => {
            setSelectedSMSTemplate(record);
            setShowUpdateSMSTemplateModal(true);
          }}
        >
          Edit
        </Button>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>Templates Admin | Mabel</title>
      </Helmet>
      <Title level={3}>
        <PaperPlaneOutlined /> Letter Templates
      </Title>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Row gutter={[8, 8]} justify="center">
          <Col flex="auto">
            <Input.Search
              defaultValue={initialLetterTemplateQuery || undefined}
              placeholder="Search by letter template name or id"
              enterButton
              allowClear
              loading={searchLetterTemplatesQueryResult.loading}
              onSearch={(value) => {
                const trimmedValue = value;
                const newParams = {
                  exact_query: trimmedValue,
                  substring_query: trimmedValue ? `%${trimmedValue}%` : null,
                  offset: 0,
                };
                setSearchLetterTemplateParams((old) => {
                  return {
                    variables: { ...old.variables, ...newParams },
                    pagination: { ...old.pagination, current: 1 },
                  };
                });
              }}
              onChange={(e) => {
                const trimmedValue = e.target.value.trim();
                const newParams = {
                  exact_query: trimmedValue,
                  substring_query: trimmedValue ? `%${trimmedValue}%` : null,
                  offset: 0,
                };
                setSearchLetterTemplateParams((old) => {
                  return {
                    variables: { ...old.variables, ...newParams },
                    pagination: { ...old.pagination, current: 1 },
                  };
                });
              }}
            />
          </Col>
          <Col>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setSelectedLetterTemplate(undefined);
                setShowCreateLetterTemplateModal(true);
              }}
            >
              Add Letter Template
            </Button>
            <LetterTemplateMutationModal
              formName="Create Letter Template"
              visible={showCreateLetterTemplateModal}
              onCancel={() => setShowCreateLetterTemplateModal(false)}
              afterSubmit={() => setShowCreateLetterTemplateModal(false)}
            />
            <LetterTemplateMutationModal
              formName="Update Letter Template"
              initialRecord={selectedLetterTemplate}
              visible={showUpdateLetterTemplateModal}
              onCancel={() => setShowUpdateLetterTemplateModal(false)}
              afterSubmit={() => setShowUpdateLetterTemplateModal(false)}
            />
            <Modal
              visible={letterMergeVarsModalVisible}
              title={
                <Typography.Text>
                  Modify default variables{' '}
                  <Tooltip title="Values will only be used if not defined at the campaign, component, practice, or patient level">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </Typography.Text>
              }
              okText="Save"
              cancelText="Cancel"
              confirmLoading={updateLetterTemplateMutationResults.loading}
              onCancel={() => {
                letterMergeVarsForm.resetFields();
                setLetterMergeVarModalVisible(false);
              }}
              onOk={letterMergeVarsForm.submit}
            >
              <KeyValueForm
                form={letterMergeVarsForm}
                onFinish={(vars) => {
                  updateLetterTemplateMutation({
                    variables: {
                      letter_template_id: selectedLetterTemplate!
                        .letter_template_id,
                      changes: { default_merge_vars: vars },
                    },
                  }).then(() => setLetterMergeVarModalVisible(false));
                }}
              />
            </Modal>
          </Col>
        </Row>
        <Col flex="auto">
          <Table<LetterTemplateBasicFragment>
            size="small"
            rowKey="letter_template_id"
            loading={searchLetterTemplatesQueryResult.loading}
            dataSource={searchLetterTemplatesQueryResult.data?.letter_templates}
            pagination={{
              ...searchLetterTemplateParams.pagination,
              position: ['bottomRight'],
              showSizeChanger: true,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              total:
                searchLetterTemplatesQueryResult.data
                  ?.letter_templates_aggregate.aggregate?.count || 0,
            }}
            columns={letter_columns}
            showSorterTooltip={false}
            onChange={(pagination, filters, sorter, extra) => {
              if (extra.action === 'sort') {
                const new_order = !sorter
                  ? []
                  : (sorter instanceof Array ? sorter : [sorter])
                      .filter((c) => c.field && c.order)
                      .map((column) => {
                        const key = column.field?.toString() || '';
                        const order =
                          column.order === 'ascend'
                            ? Order_By.AscNullsFirst
                            : Order_By.DescNullsLast;
                        return column.field instanceof Array
                          ? column.field.reduceRight(
                              (value: any, parent: any) => ({
                                [parent]: value,
                              }),
                              order
                            )
                          : { [key]: order };
                      });
                const newParams = {
                  order_by: !new_order.length
                    ? initialLetterTemplateSearchParams.variables.order_by
                    : new_order,
                };
                setSearchLetterTemplateParams((old) => {
                  return {
                    variables: { ...old.variables, ...newParams },
                    pagination: old.pagination,
                  };
                });
              } else if (extra.action === 'filter') {
                const additional_filters: any[] = [];
                for (const k in filters) {
                  if (filters[k]?.length) {
                    additional_filters.push({ [k]: { _in: filters[k] } });
                  }
                }
                const newParams = {
                  offset: 0,
                  additional_filters: additional_filters,
                };
                setSearchLetterTemplateParams((old) => {
                  return {
                    variables: { ...old.variables, ...newParams },
                    pagination: { ...old.pagination, current: 1 },
                  };
                });
              } else if (extra.action === 'paginate') {
                const newParams = {
                  limit: pagination.pageSize,
                  offset:
                    (pagination.pageSize || 0) *
                    ((pagination.current || 0) - 1),
                };
                setSearchLetterTemplateParams((old) => {
                  return {
                    variables: { ...old.variables, ...newParams },
                    pagination: {
                      ...old.pagination,
                      current: pagination.current || 1,
                      pageSize: pagination.pageSize || old.pagination.pageSize,
                    },
                  };
                });
              }
            }}
            expandable={{
              expandedRowRender: (record) => (
                <Row>
                  <Col span={12}>
                    <Descriptions
                      title={record.letter_template_name}
                      layout="horizontal"
                      column={1}
                      style={{ margin: '24px 48px' }}
                    >
                      <Descriptions.Item label="ID">
                        {record.letter_template_id}
                      </Descriptions.Item>
                      <Descriptions.Item label="External ID">
                        {record.external_id}
                      </Descriptions.Item>
                      <Descriptions.Item label="External Secondary ID">
                        {record.external_secondary_id}
                      </Descriptions.Item>
                      <Descriptions.Item label="From">
                        {record.letter_from}
                      </Descriptions.Item>
                      <Descriptions.Item label="Company">
                        {record.letter_company}
                      </Descriptions.Item>
                      <Descriptions.Item label="Format">
                        {record.format}
                      </Descriptions.Item>
                      <Descriptions.Item label="Size">
                        {record.size}
                      </Descriptions.Item>
                      <Descriptions.Item label="Page Count">
                        {record.num_pages}
                      </Descriptions.Item>
                      <Descriptions.Item label="Perforated Page Number">
                        {record.perforated_page}
                      </Descriptions.Item>
                      <Descriptions.Item label="Color">
                        {record.is_color ? (
                          <ColorWheel style={{ width: 20, height: 20 }} />
                        ) : (
                          <BlackAndWhiteWheel
                            style={{ width: 20, height: 20 }}
                          />
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item label="Webform URL">
                        {record.webform_url}
                      </Descriptions.Item>
                      <Descriptions.Item label="Default Merge Vars">
                        <Typography.Text
                          ellipsis
                          code
                          editable={{
                            tooltip: false,
                            editing: false,
                            onStart: () => {
                              setSelectedLetterTemplate(record);
                              letterMergeVarsForm.setFieldsValue(
                                variablesToFormVals(record.default_merge_vars)
                              );
                              setLetterMergeVarModalVisible(true);
                            },
                          }}
                        >
                          {JSON.stringify(
                            record.default_merge_vars || {},
                            null,
                            2
                          )}
                        </Typography.Text>
                      </Descriptions.Item>
                      <Descriptions.Item label="Public">
                        <Checkbox checked={record.is_public} disabled />
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                  <Col span={12}>
                    <LetterTemplatePreview
                      letter_template={record}
                      practice={{} as PracticeDetailsFragment}
                      key={record.letter_template_id}
                    />
                  </Col>
                </Row>
              ),
            }}
          />
        </Col>
        <Title level={3} style={{ marginTop: 32 }}>
          <MailOutlined /> Email Templates
        </Title>
        <Row gutter={[8, 8]} justify="center">
          <Col flex="auto">
            <Input.Search
              defaultValue={initialEmailTemplateQuery || undefined}
              placeholder="Search by email template name or id"
              enterButton
              allowClear
              loading={searchEmailTemplatesQueryResult.loading}
              onSearch={(value) => {
                const trimmedValue = value;
                const newParams = {
                  exact_query: trimmedValue,
                  substring_query: trimmedValue ? `%${trimmedValue}%` : null,
                  offset: 0,
                };
                setSearchEmailTemplateParams((old) => {
                  return {
                    variables: { ...old.variables, ...newParams },
                    pagination: { ...old.pagination, current: 1 },
                  };
                });
              }}
              onChange={(e) => {
                const trimmedValue = e.target.value.trim();
                const newParams = {
                  exact_query: trimmedValue,
                  substring_query: trimmedValue ? `%${trimmedValue}%` : null,
                  offset: 0,
                };
                setSearchEmailTemplateParams((old) => {
                  return {
                    variables: { ...old.variables, ...newParams },
                    pagination: { ...old.pagination, current: 1 },
                  };
                });
              }}
            />
          </Col>
          <Col>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setSelectedEmailTemplate(undefined);
                setShowCreateEmailTemplateModal(true);
              }}
            >
              Add Email Template
            </Button>
            <EmailTemplateMutationModal
              formName="Create Email Template"
              visible={showCreateEmailTemplateModal}
              onCancel={() => setShowCreateEmailTemplateModal(false)}
              afterSubmit={() => setShowCreateEmailTemplateModal(false)}
            />
            <EmailTemplateMutationModal
              formName="Update Email Template"
              visible={showUpdateEmailTemplateModal}
              initialRecord={selectedEmailTemplate}
              onCancel={() => setShowUpdateEmailTemplateModal(false)}
              afterSubmit={() => setShowUpdateEmailTemplateModal(false)}
            />
            <Modal
              visible={emailMergeVarModalVisible}
              title={
                <Typography.Text>
                  Modify default variables{' '}
                  <Tooltip title="Values will only be used if not defined at the campaign, component, practice, or patient level">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </Typography.Text>
              }
              okText="Save"
              cancelText="Cancel"
              confirmLoading={updateEmailTemplateMutationResults.loading}
              onCancel={() => {
                emailMergeVarsForm.resetFields();
                setEmailMergeVarsModalVisible(false);
              }}
              onOk={emailMergeVarsForm.submit}
            >
              <KeyValueForm
                form={emailMergeVarsForm}
                onFinish={(vars) => {
                  updateEmailTemplateMutation({
                    variables: {
                      email_template_id: selectedEmailTemplate!
                        .email_template_id,
                      changes: { default_merge_vars: vars },
                    },
                  }).then(() => setEmailMergeVarsModalVisible(false));
                }}
              />
            </Modal>
          </Col>
        </Row>
        <Col flex="auto">
          <Table<EmailTemplateBasicFragment>
            size="small"
            rowKey="email_template_id"
            loading={searchEmailTemplatesQueryResult.loading}
            dataSource={searchEmailTemplatesQueryResult.data?.email_templates}
            pagination={{
              ...searchEmailTemplateParams.pagination,
              position: ['bottomRight'],
              showSizeChanger: true,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              total:
                searchEmailTemplatesQueryResult.data?.email_templates_aggregate
                  .aggregate?.count || 0,
            }}
            columns={email_columns}
            showSorterTooltip={false}
            onChange={(pagination, filters, sorter, extra) => {
              if (extra.action === 'sort') {
                const new_order = !sorter
                  ? []
                  : (sorter instanceof Array ? sorter : [sorter])
                      .filter((c) => c.field && c.order)
                      .map((column) => {
                        const key = column.field?.toString() || '';
                        const order =
                          column.order === 'ascend'
                            ? Order_By.AscNullsFirst
                            : Order_By.DescNullsLast;
                        return column.field instanceof Array
                          ? column.field.reduceRight(
                              (value: any, parent: any) => ({
                                [parent]: value,
                              }),
                              order
                            )
                          : { [key]: order };
                      });
                const newParams = {
                  order_by: !new_order.length
                    ? initialEmailTemplateSearchParams.variables.order_by
                    : new_order,
                };
                setSearchEmailTemplateParams((old) => {
                  return {
                    variables: { ...old.variables, ...newParams },
                    pagination: old.pagination,
                  };
                });
              } else if (extra.action === 'filter') {
                const additional_filters: any[] = [];
                for (const k in filters) {
                  if (filters[k]?.length) {
                    additional_filters.push({ [k]: { _in: filters[k] } });
                  }
                }
                const newParams = {
                  offset: 0,
                  additional_filters: additional_filters,
                };
                setSearchEmailTemplateParams((old) => {
                  return {
                    variables: { ...old.variables, ...newParams },
                    pagination: { ...old.pagination, current: 1 },
                  };
                });
              } else if (extra.action === 'paginate') {
                const newParams = {
                  limit: pagination.pageSize,
                  offset:
                    (pagination.pageSize || 0) *
                    ((pagination.current || 0) - 1),
                };
                setSearchEmailTemplateParams((old) => {
                  return {
                    variables: { ...old.variables, ...newParams },
                    pagination: {
                      ...old.pagination,
                      current: pagination.current || 1,
                      pageSize: pagination.pageSize || old.pagination.pageSize,
                    },
                  };
                });
              }
            }}
            expandable={{
              expandedRowRender: (record) => (
                <Row>
                  <Col span={12}>
                    <Descriptions
                      title={record.email_template_name}
                      layout="horizontal"
                      column={1}
                      style={{ margin: '24px 48px' }}
                    >
                      <Descriptions.Item label="ID">
                        {record.email_template_id}
                      </Descriptions.Item>
                      <Descriptions.Item label="From">
                        {record.email_from}
                      </Descriptions.Item>
                      <Descriptions.Item label="Subject Line">
                        {record.email_subject}
                      </Descriptions.Item>
                      <Descriptions.Item label="Body HTML">
                        <Input.TextArea
                          disabled
                          value={record.body_html || undefined}
                          autoSize={{ minRows: 4, maxRows: 12 }}
                        />
                      </Descriptions.Item>
                      <Descriptions.Item label="Webform URL">
                        {record.webform_url}
                      </Descriptions.Item>
                      <Descriptions.Item label="Default Merge Vars">
                        <Tooltip
                          title={
                            <pre>
                              {JSON.stringify(
                                record.default_merge_vars || {},
                                null,
                                2
                              )}
                            </pre>
                          }
                        >
                          <Typography.Text
                            ellipsis
                            code
                            editable={{
                              tooltip: false,
                              editing: false,
                              onStart: () => {
                                setSelectedEmailTemplate(record);
                                emailMergeVarsForm.setFieldsValue(
                                  variablesToFormVals(record.default_merge_vars)
                                );
                                setEmailMergeVarsModalVisible(true);
                              },
                            }}
                          >
                            {JSON.stringify(
                              record.default_merge_vars || {},
                              null,
                              2
                            )}
                          </Typography.Text>
                        </Tooltip>
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                  <Col span={12}>
                    <EmailTemplatePreview
                      email_template={record}
                      practice={{} as PracticeDetailsFragment}
                      key={record.email_template_id}
                    />
                  </Col>
                </Row>
              ),
            }}
          />
        </Col>
        <Title level={3} style={{ marginTop: 32 }}>
          <MessageOutlined /> SMS Templates
        </Title>
        <Row gutter={[8, 8]} justify="center">
          <Col flex="auto">
            <Input.Search
              defaultValue={initialSMSTemplateQuery || undefined}
              placeholder="Search by SMS template name or id"
              enterButton
              allowClear
              loading={searchSMSTemplatesQueryResult.loading}
              onSearch={(value) => {
                const trimmedValue = value;
                const newParams = {
                  exact_query: trimmedValue,
                  substring_query: trimmedValue ? `%${trimmedValue}%` : null,
                  offset: 0,
                };
                setSearchSMSTemplateParams((old) => {
                  return {
                    variables: { ...old.variables, ...newParams },
                    pagination: { ...old.pagination, current: 1 },
                  };
                });
              }}
              onChange={(e) => {
                const trimmedValue = e.target.value.trim();
                const newParams = {
                  exact_query: trimmedValue,
                  substring_query: trimmedValue ? `%${trimmedValue}%` : null,
                  offset: 0,
                };
                setSearchSMSTemplateParams((old) => {
                  return {
                    variables: { ...old.variables, ...newParams },
                    pagination: { ...old.pagination, current: 1 },
                  };
                });
              }}
            />
          </Col>
          <Col>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setSelectedSMSTemplate(undefined);
                setShowCreateSMSTemplateModal(true);
              }}
            >
              Add SMS Template
            </Button>
            <SMSTemplateMutationModal
              formName="Create SMS Template"
              visible={showCreateSMSTemplateModal}
              onCancel={() => setShowCreateSMSTemplateModal(false)}
              afterSubmit={() => setShowCreateSMSTemplateModal(false)}
            />
            <SMSTemplateMutationModal
              formName="Update SMS Template"
              visible={showUpdateSMSTemplateModal}
              initialRecord={selectedSMSTemplate}
              onCancel={() => setShowUpdateSMSTemplateModal(false)}
              afterSubmit={() => setShowUpdateSMSTemplateModal(false)}
            />
            <Modal
              visible={SMSMergeVarsModalVisible}
              title={
                <Typography.Text>
                  Modify default variables{' '}
                  <Tooltip title="Values will only be used if not defined at the campaign, component, practice, or patient level">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </Typography.Text>
              }
              okText="Save"
              cancelText="Cancel"
              confirmLoading={updateSMSTemplateMutationResults.loading}
              onCancel={() => {
                SMSMergeVarsForm.resetFields();
                setSMSMergeVarsModalVisible(false);
              }}
              onOk={SMSMergeVarsForm.submit}
            >
              <KeyValueForm
                form={SMSMergeVarsForm}
                onFinish={(vars) => {
                  updateSMSTemplateMutation({
                    variables: {
                      sms_template_id: selectedSMSTemplate!.sms_template_id,
                      changes: { default_merge_vars: vars },
                    },
                  }).then(() => setSMSMergeVarsModalVisible(false));
                }}
              />
            </Modal>
          </Col>
        </Row>
        <Col flex="auto">
          <Table<SmsTemplateBasicFragment>
            size="small"
            rowKey="sms_template_id"
            loading={searchSMSTemplatesQueryResult.loading}
            dataSource={searchSMSTemplatesQueryResult.data?.sms_templates}
            pagination={{
              ...searchSMSTemplateParams.pagination,
              position: ['bottomRight'],
              showSizeChanger: true,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              total:
                searchSMSTemplatesQueryResult.data?.sms_templates_aggregate
                  .aggregate?.count || 0,
            }}
            columns={sms_columns}
            showSorterTooltip={false}
            onChange={(pagination, filters, sorter, extra) => {
              if (extra.action === 'sort') {
                const new_order = !sorter
                  ? []
                  : (sorter instanceof Array ? sorter : [sorter])
                      .filter((c) => c.field && c.order)
                      .map((column) => {
                        const key = column.field?.toString() || '';
                        const order =
                          column.order === 'ascend'
                            ? Order_By.AscNullsFirst
                            : Order_By.DescNullsLast;
                        return column.field instanceof Array
                          ? column.field.reduceRight(
                              (value: any, parent: any) => ({
                                [parent]: value,
                              }),
                              order
                            )
                          : { [key]: order };
                      });
                const newParams = {
                  order_by: !new_order.length
                    ? initialSMSTemplateSearchParams.variables.order_by
                    : new_order,
                };
                setSearchSMSTemplateParams((old) => {
                  return {
                    variables: { ...old.variables, ...newParams },
                    pagination: old.pagination,
                  };
                });
              } else if (extra.action === 'filter') {
                const additional_filters: any[] = [];
                for (const k in filters) {
                  if (filters[k]?.length) {
                    additional_filters.push({ [k]: { _in: filters[k] } });
                  }
                }
                const newParams = {
                  offset: 0,
                  additional_filters: additional_filters,
                };
                setSearchSMSTemplateParams((old) => {
                  return {
                    variables: { ...old.variables, ...newParams },
                    pagination: { ...old.pagination, current: 1 },
                  };
                });
              } else if (extra.action === 'paginate') {
                const newParams = {
                  limit: pagination.pageSize,
                  offset:
                    (pagination.pageSize || 0) *
                    ((pagination.current || 0) - 1),
                };
                setSearchSMSTemplateParams((old) => {
                  return {
                    variables: { ...old.variables, ...newParams },
                    pagination: {
                      ...old.pagination,
                      current: pagination.current || 1,
                      pageSize: pagination.pageSize || old.pagination.pageSize,
                    },
                  };
                });
              }
            }}
            expandable={{
              expandedRowRender: (record) => (
                <Row>
                  <Col span={12}>
                    <Descriptions
                      title={record.sms_template_name}
                      layout="horizontal"
                      column={1}
                      style={{ margin: '24px 48px' }}
                    >
                      <Descriptions.Item label="ID">
                        {record.sms_template_id}
                      </Descriptions.Item>
                      <Descriptions.Item label="Body Text">
                        <Input.TextArea
                          disabled
                          value={record.body_text || undefined}
                          autoSize={{ minRows: 4, maxRows: 12 }}
                        />
                      </Descriptions.Item>
                      <Descriptions.Item label="Webform URL">
                        {record.webform_url}
                      </Descriptions.Item>
                      <Descriptions.Item label="Default Merge Vars">
                        <Tooltip
                          title={
                            <pre>
                              {JSON.stringify(
                                record.default_merge_vars || {},
                                null,
                                2
                              )}
                            </pre>
                          }
                        >
                          <Typography.Text
                            ellipsis
                            code
                            editable={{
                              tooltip: false,
                              editing: false,
                              onStart: () => {
                                setSelectedSMSTemplate(record);
                                SMSMergeVarsForm.setFieldsValue(
                                  variablesToFormVals(record.default_merge_vars)
                                );
                                setSMSMergeVarsModalVisible(true);
                              },
                            }}
                          >
                            {JSON.stringify(
                              record.default_merge_vars || {},
                              null,
                              2
                            )}
                          </Typography.Text>
                        </Tooltip>
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                  <Col span={12}>
                    <SMSTemplatePreview
                      sms_template={record}
                      practice={{} as PracticeDetailsFragment}
                      key={record.sms_template_id}
                    />
                  </Col>
                </Row>
              ),
            }}
          />
        </Col>
      </Space>
    </>
  );
};

export default TemplatesAdminPage;
