import React, { useState } from 'react';
import { Auth } from 'aws-amplify';

import { Button, Dropdown, Menu, message, Result, Space, Spin } from 'antd';

import axios from 'axios';
import { useRequest } from 'ahooks';
import IframeResizer from 'iframe-resizer-react';

import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { graphConfig, loginScopes } from '../../MSauthConfig';
import {
  DownloadOutlined,
  LogoutOutlined,
  ScissorOutlined,
} from '@ant-design/icons';
import { AuthenticationResult } from '@azure/msal-browser';
import { PDFDocument } from 'pdf-lib';
import JSZip from 'jszip';
import QrScanner from 'qr-scanner';
import * as pdfjsLib from 'pdfjs-dist';
import { ShortURLResponse } from './AlignmentsTable';
pdfjsLib.GlobalWorkerOptions.workerSrc = '/static/js/pdf.worker.min.js';

const AlignmentPDFPreview: React.FC<{
  submission_id: string;
  submission_source: string;
  onedrive_file_id?: string | null;
  onQrCodeDetection?: (qrCode: (ShortURLResponse | null)[]) => void;
}> = ({
  submission_id,
  submission_source,
  onedrive_file_id,
  onQrCodeDetection,
}) => {
  const isMSAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState<null | string>(null);

  const getOneDrivePreview = async () => {
    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    let token = accessToken;
    if (!token) {
      const request = {
        ...loginScopes,
        account: accounts[0],
      };
      let authResult: AuthenticationResult;
      try {
        authResult = await instance.acquireTokenSilent(request);
      } catch (error) {
        authResult = await instance.acquireTokenPopup(request);
      }
      setAccessToken(authResult.accessToken);
      token = authResult.accessToken;
    }

    let drive_id = onedrive_file_id?.split('.')[0];
    let file_id = onedrive_file_id?.split('.')[1];

    // Legacy: if the file id isn't already present, look it up using submission id
    if (!file_id || !drive_id) {
      drive_id = process.env.REACT_APP_ONEDRIVE_DRIVE_ID;
      // Once token acquired, get preview by first getting the file id
      let pathPrefix =
        `/drives/${process.env.REACT_APP_ONEDRIVE_DRIVE_ID}/root:/${process.env.REACT_APP_ONEDRIVE_ROOT_FOLDER}` +
        ([
          'StableMail',
          'EarthClassMail',
          'HelloFax',
          'DropboxUpload',
          'WebUpload',
        ].includes(submission_source)
          ? '/OCR' // The automatically OCR'd submission sources get put in a subfolder
          : '');
      let fileType = submission_source === 'LegacyNeueHealth' ? 'tif' : 'pdf';

      const itemByPathResult = await axios.get(
        graphConfig.graphMeEndpoint +
          encodeURIComponent(
            `${pathPrefix}/${submission_source}/${submission_id}.${fileType}`
          ),
        { headers: { Authorization: `Bearer ${token}` } }
      );
      file_id = itemByPathResult.data.id;
    }

    const itemByIdResult = await Promise.all([
      axios.get(
        graphConfig.graphMeEndpoint + `/drives/${drive_id}/items/${file_id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      ),
      axios.post(
        graphConfig.graphMeEndpoint +
          `/drives/${drive_id}/items/${file_id}/preview`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      ),
    ]);

    return {
      downloadUrl: itemByIdResult[0].data['@microsoft.graph.downloadUrl'],
      embedUrl: itemByIdResult[1].data.getUrl,
    };
  };

  const oneDrivePreviewRequest = useRequest(getOneDrivePreview, {
    cacheKey: onedrive_file_id || `${submission_source}:${submission_id}`,
  });

  const getPDFBlob = useRequest(
    () =>
      axios.get(oneDrivePreviewRequest.data!.downloadUrl, {
        responseType: 'arraybuffer',
      }),
    {
      cacheKey: oneDrivePreviewRequest?.data?.downloadUrl,
      ready: !!oneDrivePreviewRequest?.data?.downloadUrl,
      onSuccess: async (result) => {
        const parsedQRCodes = await getQRCodesInPages(result.data);
        if (onQrCodeDetection) onQrCodeDetection(parsedQRCodes);
      },
    }
  );

  const isValidHttpsUrl = (s: string) => {
    let url;
    try {
      url = new URL(s);
    } catch (_) {
      return false;
    }
    return url.protocol === 'https:';
  };

  const getQRCodesInPages = async (
    pdfBlob: ArrayBuffer
  ): Promise<(ShortURLResponse | null)[]> => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();

    const pdf = await pdfjsLib.getDocument(pdfBlob).promise;
    const qrCodesInPages = await Promise.all(
      Array.from({ length: pdf.numPages }, async (_, i) => {
        const page = await pdf.getPage(i + 1);
        const viewport = page.getViewport({ scale: 1 });
        const canvas = new OffscreenCanvas(viewport.width, viewport.height);
        await page.render({
          canvasContext: canvas.getContext('2d')!,
          viewport: viewport,
        }).promise;
        const code = await QrScanner.scanImage(canvas, {
          returnDetailedScanResult: true,
        }).catch((e) => {
          return { data: null };
        });
        if (!code.data || !isValidHttpsUrl(code.data)) {
          return null;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_CHALICE_URL}/parse_short_url?short_url=${code.data}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        return response.data;
      })
    );
    return qrCodesInPages;
  };

  const downloadPages = async (url: string) => {
    const jszip = new JSZip();
    const outputfolder = jszip.folder(submission_id);

    const pdfDoc = await PDFDocument.load(getPDFBlob.data?.data);
    const numPages = pdfDoc.getPageCount();

    for (let i = 0; i < numPages; i++) {
      const newPage = await PDFDocument.create();
      const [page] = await newPage.copyPages(pdfDoc, [i]);
      newPage.addPage(page);
      const bytes = await newPage.save();
      outputfolder?.file(`${submission_id}.${i + 1}.pdf`, new Blob([bytes]));
    }

    const blob = await jszip.generateAsync({ type: 'blob' });
    saveAs(blob, `${submission_id}.zip`);
  };
  const downloadPagesRequest = useRequest(downloadPages, { manual: true });

  return isMSAuthenticated ? (
    <Spin spinning={oneDrivePreviewRequest.loading}>
      <Button
        icon={<LogoutOutlined />}
        type="primary"
        size="small"
        style={{ backgroundColor: '#0001FF', float: 'right', marginBottom: 4 }}
        onClick={() => {
          instance.logoutPopup().catch((e) => {
            console.error(e);
          });
        }}
      >
        Logout of OneDrive{' '}
        <img
          alt="MS Logo"
          src="https://upload.wikimedia.org/wikipedia/commons/4/44/Microsoft_logo.svg"
          style={{ marginLeft: 8, width: 12 }}
        />
      </Button>
      {oneDrivePreviewRequest.error ? (
        <Result
          status="error"
          title="Error retrieving file from OneDrive"
          subTitle={oneDrivePreviewRequest.error.message}
        />
      ) : (
        <Space direction="vertical" style={{ width: '100%' }}>
          <IframeResizer
            src={oneDrivePreviewRequest.data?.embedUrl}
            style={{ width: '1px', minWidth: '100%' }}
            height={800}
            frameBorder={0}
          />
          <Dropdown.Button
            style={{ float: 'right' }}
            href={oneDrivePreviewRequest.data?.downloadUrl}
            overlay={
              <Menu>
                <Menu.Item
                  icon={<ScissorOutlined />}
                  disabled={!getPDFBlob.data}
                  onClick={() =>
                    downloadPagesRequest.run(
                      oneDrivePreviewRequest.data?.downloadUrl
                    )
                  }
                >
                  Download and Split
                </Menu.Item>
              </Menu>
            }
          >
            <DownloadOutlined />
            Download from OneDrive
          </Dropdown.Button>
        </Space>
      )}
    </Spin>
  ) : (
    <Result
      icon={
        <img
          alt="MS Logo"
          src="https://upload.wikimedia.org/wikipedia/commons/4/44/Microsoft_logo.svg"
          width={96}
        />
      }
      subTitle="Login in to OneDrive to view previews"
      extra={
        <Button
          style={{ backgroundColor: '#0001FF' }}
          type="primary"
          onClick={() =>
            instance.loginPopup(loginScopes).catch((e) => {
              message.error(e.message);
            })
          }
        >
          Sign In To OneDrive
        </Button>
      }
    />
  );
};
export default AlignmentPDFPreview;
