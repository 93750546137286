import React, { useState, useEffect, useContext } from 'react';

import { v4 as uuidv4 } from 'uuid';

import { Form, Input, Modal, Button } from 'antd';
import { FormProps } from 'antd/lib/form';
import { useForm } from 'antd/lib/form/Form';
import { Store } from 'antd/lib/form/interface';
import {
  PracticeDetailsFragment,
  SearchPracticesDocument,
  SearchPracticesAdvancedDocument,
  useUpdatePracticeMutation,
  useCreatePracticeMutation,
} from '../../graphql/generated';
import { getOperationAST } from 'graphql';

import { UserContext } from '../../contexts/UserContext';
import { NetworkSearchSelect, ProviderSearchSelect } from '../autocompletes';
import PublicImageUpload from '../utils/PublicImageUpload';
import ColorInput from '../utils/ColorInput';
import ColorThief from 'colorthief';

interface PracticeFormProps extends FormProps {
  initialPractice?: PracticeDetailsFragment;
  updateMutation?: ReturnType<typeof useUpdatePracticeMutation>[0];
  createMutation?: ReturnType<typeof useCreatePracticeMutation>[0];
  showSubmit?: boolean;
  haveValuesChanged?: boolean;
  setHaveValuesChanged?: React.Dispatch<React.SetStateAction<boolean>>;
}

const PracticeForm: React.FC<PracticeFormProps> = ({
  form,
  initialPractice,
  updateMutation,
  createMutation,
  showSubmit,
  haveValuesChanged,
  setHaveValuesChanged,
  ...rest
}) => {
  const { user } = useContext(UserContext);
  const [dominantColors, setDominantColors] = useState<string[]>([]);

  const [formInternal] = useForm(form);
  const [
    updateMutationInternal,
    updateMutationInternalResults,
  ] = useUpdatePracticeMutation();
  const [
    createMutationInternal,
    createMutationInternalResults,
  ] = useCreatePracticeMutation();

  // If an initial practice is given, this populates the form
  const recordToFormVals = (record: PracticeDetailsFragment) => {
    return {
      ...record,
      practice_logo: { url: record.practice_logo },
      primary_network_id: record.primary_network
        ? {
            value: record.primary_network?.network_id,
            label: record.primary_network?.network_name,
          }
        : undefined,
      default_provider_id: record.default_provider
        ? {
            value: record.default_provider?.provider_id,
            label: record.default_provider?.provider_name,
          }
        : undefined,
      provider_employments: record.provider_employments
        .filter((e) => !e.is_archived && !e.provider.is_archived)
        .map((e) => {
          return {
            value: e.provider.provider_id,
            label: e.provider.provider_name,
          };
        }),
    };
  };
  useEffect(() => {
    if (!initialPractice) {
      formInternal.setFieldsValue({ practice_id: uuidv4() });
    } else {
      formInternal.resetFields();
      formInternal.setFieldsValue(recordToFormVals(initialPractice));
      if (initialPractice.practice_logo) {
        const image = new Image();
        image.crossOrigin = 'Anonymous';
        image.onload = function () {
          const colorThief = new ColorThief();
          const palette = colorThief.getPalette(image, 8);
          if (palette) {
            const hexcolors = palette.map((triple: any) =>
              rgbToHex(triple[0], triple[1], triple[2])
            );
            setDominantColors(hexcolors);
          }
        };
        image.src = initialPractice.practice_logo;
      }
    }
  }, [formInternal, initialPractice]);

  // Takes the form vals and puts them into right format for writing
  const formValsToRecordChanges = (formValues: Store) => {
    const { provider_employments, ...rest } = formValues;
    const new_obj = {
      ...rest,
      practice_logo: formValues.practice_logo?.url || null,
      primary_network_id: formValues.primary_network_id?.value || null,
      default_provider_id: formValues.default_provider_id?.value || null,
    };
    const changes = initialPractice
      ? Object.fromEntries(
          Object.entries(new_obj).filter(
            ([k, v]) => (initialPractice as any)[k] !== v
          )
        )
      : new_obj;
    return changes;
  };

  const formValsToEmploymentChanges = (formValues: Store) => {
    const old_non_archived_providers = new Set(
      (initialPractice?.provider_employments || [])
        .filter((e) => !e.is_archived)
        .map((e) => e.provider.provider_id)
    );
    const current_providers = new Set<string>(
      (formValues.provider_employments || []).map((p: any) => p.value)
    );
    const deleted_providers = [...old_non_archived_providers].filter(
      (k) => !current_providers.has(k)
    );
    const added_providers = [...current_providers].filter(
      (k) => !old_non_archived_providers.has(k)
    );
    const updated_employments = [
      ...deleted_providers.map((p) => {
        return {
          practice_id: initialPractice?.practice_id,
          provider_id: p,
          is_archived: true,
        };
      }),
      ...added_providers.map((p) => {
        return {
          practice_id: initialPractice?.practice_id,
          provider_id: p,
          is_archived: false,
        };
      }),
    ];
    return updated_employments;
  };

  const valuesHaveChanged = (formValues: Store) => {
    return (
      Object.keys(formValsToRecordChanges(formValues)).length > 0 ||
      Object.keys(formValsToEmploymentChanges(formValues)).length > 0
    );
  };

  const handleInsert = (values: any) => {
    const practice = {
      ...formValsToRecordChanges(values),
      provider_employments: {
        data: formValsToEmploymentChanges(values),
      },
    };
    (createMutation || createMutationInternal)({
      variables: { practice: practice },
      refetchQueries: [
        // This just gets the name of the query to refresh.
        // By passing it as just a string, we force a refresh of *all*
        // calls made by the query, regardless of variable values
        // This is useful since we're fetching pages individually, and
        // after inserting a new document then any page might have changed
        getOperationAST(SearchPracticesDocument)?.name?.value || '',
        getOperationAST(SearchPracticesAdvancedDocument)?.name?.value || '',
      ],
    });
  };

  const handleUpdate = (values: any) => {
    if (!initialPractice) {
      return;
    }
    const changes = formValsToRecordChanges(values);
    (updateMutation || updateMutationInternal)({
      variables: {
        practice_id: initialPractice.practice_id,
        changes: Object.keys(changes).length ? changes : undefined,
        provider_employments: formValsToEmploymentChanges(values),
      },
    });
    if (setHaveValuesChanged) setHaveValuesChanged(false);
  };
  const readFilePromise = (file: File) => {
    return new Promise<string>((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result?.toString());
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const getColorPalette = async (
    file: File,
    colorCount?: number,
    quality?: number
  ) => {
    return new Promise<number[][]>((resolve, reject) => {
      readFilePromise(file).then((fileContents) => {
        const image = new Image();
        const colorThief = new ColorThief();
        image.onload = function () {
          resolve(colorThief.getPalette(image, colorCount, quality));
        };
        image.src = fileContents;
      });
    });
  };
  const getDominantColor = async (file: File, quality?: number) => {
    return new Promise<number[]>((resolve, reject) => {
      readFilePromise(file).then((fileContents) => {
        const image = new Image();
        const colorThief = new ColorThief();
        image.onload = function () {
          resolve(colorThief.getColor(image, quality));
        };
        image.src = fileContents;
      });
    });
  };
  const rgbToHex = (r: number, g: number, b: number) =>
    '#' +
    [r, g, b]
      .map((x) => {
        const hex = x.toString(16);
        return hex.length === 1 ? '0' + hex : hex;
      })
      .join('');

  return (
    <Form
      {...rest}
      form={formInternal}
      labelCol={rest.labelCol || { span: 6 }}
      wrapperCol={rest.wrapperCol || { span: 18 }}
      onValuesChange={(_, allValues) =>
        setHaveValuesChanged
          ? setHaveValuesChanged(valuesHaveChanged(allValues))
          : null
      }
      onFinish={initialPractice ? handleUpdate : handleInsert}
    >
      <Form.Item
        label="Practice Name"
        name="practice_name"
        rules={[
          {
            required: true,
            whitespace: true,
            message: 'Please provide a practice name',
          },
          {
            max: 40,
            message: 'Practice name must be under 40 characters',
          },
        ]}
      >
        <Input placeholder="Name" />
      </Form.Item>
      <Form.Item
        label="Company or Byline"
        name="practice_company"
        help="Optional extra line of text displayed between the practice's name and the return address on direct mail"
      >
        <Input placeholder="Company or Byline" />
      </Form.Item>
      <Form.Item
        label="Address"
        name="practice_address"
        rules={[
          {
            required: true,
            whitespace: true,
            message: 'Please provide an address',
          },
        ]}
        help="This will be used as the return address on direct mail"
      >
        <Input.TextArea placeholder="Address" autoSize={{ minRows: 2 }} />
      </Form.Item>
      <Form.Item
        label="Description"
        name="practice_description"
        help="Optional description used to help identify the practice within Mabel"
      >
        <Input placeholder="Description" />
      </Form.Item>
      <Form.Item
        label="Legal Business Name"
        name="legal_business_name"
        help="Legal name associated with org or individual NPI"
      >
        <Input placeholder="Practice Name, Inc." />
      </Form.Item>
      <Form.Item
        label="Main Doctor Name Extension"
        name="main_doctor_name_extension"
        help="Used for additional text in the main doctor section of SVA form"
      >
        <Input.TextArea placeholder="☐ Dr A   ☐ Dr B...." />
      </Form.Item>
      <Form.Item
        label="Language"
        name="practice_language"
        help="Preferred language, e.g. EN or EN/SP"
      >
        <Input />
      </Form.Item>
      <Form.Item label="TIN" name="tin" help="Taxpayer Identification Number">
        <Input />
      </Form.Item>
      <Form.Item
        label="Providers"
        name="provider_employments"
        key="provider_employments"
        hidden={user?.role !== 'admin'}
      >
        <ProviderSearchSelect
          allowClear
          mode="multiple"
          onChange={(value) => {
            form?.setFieldsValue({ provider_employments: value });
          }}
        />
      </Form.Item>
      <Form.Item
        label="Default Provider"
        name="default_provider_id"
        hidden={user?.role !== 'admin'}
      >
        <ProviderSearchSelect
          allowClear
          showSearch
          onChange={(value) => {
            form?.setFieldsValue({ default_provider_id: value });
          }}
        />
      </Form.Item>
      <Form.Item
        label="Preferred Network"
        name="primary_network_id"
        hidden={user?.role !== 'admin'}
        rules={[
          {
            required: true,
            message: 'A preferred network is required',
          },
        ]}
      >
        <NetworkSearchSelect
          allowClear
          onChange={(value) => {
            form?.setFieldsValue({ primary_network_id: value });
          }}
        />
      </Form.Item>
      <Form.Item name="practice_id" hidden={true}></Form.Item>
      <Form.Item
        label="Logo"
        name="practice_logo"
        help="Optimal size is 480 x 180px. Must be .jpg or .png file less than 2MB."
        dependencies={['practice_id']}
      >
        <PublicImageUpload
          uploadPath={`practice/${formInternal.getFieldValue(
            'practice_id'
          )}/logos/${new Date().getTime()}/`}
          cropProps={{
            rotate: true,
            aspect: 480 / 180,
            minZoom: 0.5,
            cropperProps: { restrictPosition: false },
          }}
          previewStyle={{ width: 320, height: 120 }}
          maxFileSize={2 * 1024 * 1024} //2 MB
          targetHeight={180}
          targetWidth={480}
          onChange={(uploadVal) => {
            if (uploadVal.file) {
              getColorPalette(uploadVal.file, 8).then((palette) => {
                if (palette) {
                  const hexcolors = palette.map((triple) =>
                    rgbToHex(triple[0], triple[1], triple[2])
                  );
                  setDominantColors(hexcolors);
                }
              });
              getDominantColor(uploadVal.file).then((color) => {
                if (color) {
                  formInternal.setFieldsValue({
                    practice_color: rgbToHex(color[0], color[1], color[2]),
                  });
                }
              });
            }
          }}
        />
      </Form.Item>

      <Form.Item label="Brand Color" name="practice_color">
        <ColorInput presetColors={dominantColors} />
      </Form.Item>
      {showSubmit ? (
        <Form.Item style={{ marginTop: 12 }} wrapperCol={{ offset: 6 }}>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!haveValuesChanged}
            loading={
              createMutationInternalResults.loading ||
              updateMutationInternalResults.loading
            }
          >
            Save
          </Button>
        </Form.Item>
      ) : null}
    </Form>
  );
};

interface PracticeMutationModalProps {
  formName: string;
  initialRecord?: PracticeDetailsFragment;
  visible: boolean;
  onCancel?: (() => void) | undefined;
  afterSubmit?: (() => void) | undefined;
}

const PracticeMutationModal: React.FC<PracticeMutationModalProps> = ({
  initialRecord,
  visible,
  onCancel,
  afterSubmit,
}) => {
  const [form] = useForm();
  const [haveValuesChanged, setHaveValuesChanged] = useState(false);

  const [updateMutation, updateMutationResult] = useUpdatePracticeMutation({
    onCompleted: () => {
      form.resetFields();
      if (afterSubmit) afterSubmit();
    },
  });
  const [createMutation, createMutationResult] = useCreatePracticeMutation({
    onCompleted: () => {
      form.resetFields();
      if (afterSubmit) afterSubmit();
    },
  });

  return (
    <Modal
      title={initialRecord ? 'Update Practice' : 'Create Practice'}
      okText={initialRecord ? 'Update Practice' : 'Create Practice'}
      okButtonProps={{ disabled: initialRecord && !haveValuesChanged }}
      visible={visible}
      onCancel={onCancel}
      onOk={(e) => form.submit()}
      confirmLoading={
        updateMutationResult.loading || createMutationResult.loading
      }
      width={600}
    >
      <PracticeForm
        form={form}
        initialPractice={initialRecord}
        updateMutation={updateMutation}
        createMutation={createMutation}
        haveValuesChanged={haveValuesChanged}
        setHaveValuesChanged={setHaveValuesChanged}
      />
    </Modal>
  );
};

export { PracticeForm, PracticeMutationModal };
