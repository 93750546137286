import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Typography,
  Result,
  Button,
  Table,
  Space,
  Descriptions,
  Tooltip,
  Popconfirm,
  Modal,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  PlusOutlined,
  StopOutlined,
  CheckOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

import {
  ListNetworksQuery,
  useListNetworksQuery,
  useUpdateNetworkMutation,
} from '../graphql/generated';

import { NetworkMutationModal } from '../components/networks/NetworkForm';

import { NetworkOutlined } from '../components/MabelIcons';
import SwitchRoleButton from '../components/utils/SwitchRoleButton';
import { useForm } from 'antd/es/form/Form';
import KeyValueForm, {
  variablesToFormVals,
} from '../components/utils/KeyValueForm';
import PracticeTag from '../components/practices/PracticeTag';

const { Title } = Typography;

type NetworkType = ListNetworksQuery['networks'][0];

const NetworkUpdateButton = ({
  children,
  network_id,
  changes,
  require_confirm,
  confirm_title,
}: any) => {
  const [updateMutation, updateMutationResult] = useUpdateNetworkMutation();
  return !require_confirm ? (
    <Button
      type="link"
      size="small"
      style={{ margin: 0 }}
      loading={updateMutationResult.loading}
      onClick={() => {
        updateMutation({
          variables: {
            network_id: network_id,
            changes: changes,
          },
        });
      }}
    >
      {children}
    </Button>
  ) : (
    <Popconfirm
      title={confirm_title}
      onConfirm={() => {
        updateMutation({
          variables: {
            network_id: network_id,
            changes: changes,
          },
        });
      }}
      okText="Yes"
      cancelText="No"
    >
      <Button
        type="link"
        size="small"
        style={{ margin: 0 }}
        loading={updateMutationResult.loading}
      >
        {children}
      </Button>
    </Popconfirm>
  );
};

const NetworksAdminPage = () => {
  const listNetworksQuery = useListNetworksQuery();

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<
    NetworkType | undefined
  >();

  const [
    updateNetworkMutation,
    updateNetworkMutationResult,
  ] = useUpdateNetworkMutation();
  const [mergeVarModalVisible, setMergeVarModalVisible] = useState(false);
  const [mergeVarForm] = useForm();

  if (listNetworksQuery.error)
    return (
      <Result
        status="error"
        title="There was an error retrieving networks"
        subTitle={listNetworksQuery.error?.message}
      />
    );

  const columns: ColumnsType<NetworkType> = [
    {
      title: 'Network Name',
      dataIndex: 'network_name',
      sorter: (a, b) => a.network_name.localeCompare(b.network_name),
    },
    {
      title: 'ACO ID',
      dataIndex: 'dc_id',
      sorter: (a, b) => (a.dc_id || '').localeCompare(b.dc_id || ''),
    },
    {
      title: 'Broker Name',
      dataIndex: 'broker_name',
    },
    {
      title: 'Broker Phone (emails)',
      dataIndex: 'broker_phone_for_emails',
    },
    {
      title: 'Broker Phone (letters)',
      dataIndex: 'broker_phone_for_letters',
    },
    {
      title: 'Broker URL (emails)',
      dataIndex: 'broker_url_for_emails',
    },
    {
      title: 'Broker URL (letters)',
      dataIndex: 'broker_url_for_letters',
    },
    {
      title: 'Active?',
      dataIndex: 'is_archived',
      width: 1,
      align: 'center',
      sorter: (a, b) => +a.is_archived - +b.is_archived,
      filters: [
        {
          text: 'Active',
          value: 'false',
        },
        {
          text: 'Archived',
          value: 'true',
        },
      ],
      defaultFilteredValue: ['false'],
      onFilter: (value, record) => record.is_archived.toString() === value,
      render: (is_archived) =>
        is_archived ? (
          <Tooltip title="Item has been archived">
            <StopOutlined className="danger-color" />
          </Tooltip>
        ) : (
          <CheckOutlined className="success-color" />
        ),
    },
    {
      key: 'edit',
      width: 1,
      align: 'center',
      render: (text, record) => {
        return record.is_archived ? (
          <NetworkUpdateButton
            network_id={record.network_id}
            changes={{
              is_archived: false,
            }}
          >
            Restore
          </NetworkUpdateButton>
        ) : (
          <div style={{ display: 'inline-flex' }}>
            <Button
              type="link"
              size="small"
              style={{ margin: 0 }}
              onClick={() => {
                setSelectedRecord(record);
                setShowUpdateModal(true);
              }}
            >
              Edit
            </Button>
            <NetworkUpdateButton
              network_id={record.network_id}
              changes={{
                is_archived: true,
              }}
              require_confirm={true}
              confirm_title="Archive this item?"
            >
              Archive
            </NetworkUpdateButton>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title>Networks Admin | Mabel</title>
      </Helmet>
      <Title level={3}>
        <NetworkOutlined /> Networks
      </Title>
      <Space direction="vertical" style={{ width: '100%' }}>
        <div style={{ textAlign: 'right' }}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setShowCreateModal(true)}
          >
            Add Network
          </Button>
          <NetworkMutationModal
            formName="Create"
            visible={showCreateModal}
            onCancel={() => setShowCreateModal(false)}
            afterSubmit={() => setShowCreateModal(false)}
          />
          <NetworkMutationModal
            formName="Update"
            initialRecord={selectedRecord}
            visible={showUpdateModal}
            onCancel={() => setShowUpdateModal(false)}
            afterSubmit={() => setShowUpdateModal(false)}
          />
        </div>
        <Table<NetworkType>
          size="small"
          rowKey="network_id"
          loading={listNetworksQuery.loading}
          dataSource={listNetworksQuery.data?.networks}
          pagination={{
            position: ['bottomRight'],
            showSizeChanger: true,
            defaultPageSize: 100,
          }}
          columns={columns}
          showSorterTooltip={false}
          expandable={{
            expandedRowRender: (record) => (
              <Descriptions
                title={record.network_name}
                layout="horizontal"
                column={1}
                style={{ margin: '24px 48px' }}
              >
                <Descriptions.Item label="ID">
                  {record.network_id}
                </Descriptions.Item>
                <Descriptions.Item label="Network Number">
                  {record.network_number}
                </Descriptions.Item>
                <Descriptions.Item label="ACO ID">
                  {record.dc_id}
                </Descriptions.Item>
                <Descriptions.Item label="Slug">
                  {record.network_slug}
                </Descriptions.Item>
                <Descriptions.Item label="Description">
                  {record.network_description}
                </Descriptions.Item>
                <Descriptions.Item label="Default Practice">
                  {record.default_practice && (
                    <PracticeTag practice={record.default_practice} />
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Broker Phone (emails)">
                  {record.broker_phone_for_emails}
                </Descriptions.Item>
                <Descriptions.Item label="Broker Phone (letters)">
                  {record.broker_phone_for_letters}
                </Descriptions.Item>
                <Descriptions.Item label="Broker URL (emails)">
                  {record.broker_url_for_emails}
                </Descriptions.Item>
                <Descriptions.Item label="Broker URL (letters)">
                  {record.broker_url_for_letters}
                </Descriptions.Item>
                <Descriptions.Item label="Broker Disclaimer">
                  {record.broker_disclaimer}
                </Descriptions.Item>
                <Descriptions.Item label="Logo" style={{ display: 'flex' }}>
                  {record.network_logo ? (
                    <img
                      src={record.network_logo}
                      style={{ width: 320, height: 120 }}
                      alt="network logo"
                    />
                  ) : null}
                </Descriptions.Item>
                <Descriptions.Item label="Color" style={{ display: 'flex' }}>
                  {record.network_color ? (
                    <div
                      style={{
                        padding: 4,
                        margin: 2,
                        background: '#fff',
                        borderRadius: '2px',
                        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                        display: 'inline-block',
                      }}
                    >
                      <div
                        style={{
                          width: '20px',
                          height: '20px',
                          borderRadius: '2px',
                          background: record.network_color,
                        }}
                      />
                    </div>
                  ) : null}
                </Descriptions.Item>
                <Descriptions.Item label="Network Variables">
                  <Tooltip
                    title={
                      <pre>
                        {JSON.stringify(
                          record.network_merge_vars || {},
                          null,
                          2
                        )}
                      </pre>
                    }
                  >
                    <Typography.Text
                      ellipsis
                      code
                      editable={{
                        tooltip: false,
                        editing: false,
                        onStart: () => {
                          setSelectedRecord(record);
                          mergeVarForm.setFieldsValue(
                            variablesToFormVals(record.network_merge_vars)
                          );
                          setMergeVarModalVisible(true);
                        },
                      }}
                    >
                      {JSON.stringify(record.network_merge_vars || {}, null, 2)}
                    </Typography.Text>
                  </Tooltip>
                </Descriptions.Item>
                <Descriptions.Item>
                  <SwitchRoleButton
                    network_id={record.network_id}
                    changeRole="network_user"
                  />
                </Descriptions.Item>
              </Descriptions>
            ),
          }}
        ></Table>
        <Modal
          visible={mergeVarModalVisible}
          title={
            <Typography.Text>
              Modify Network Variables{' '}
              <Tooltip title="Values will only be used if not defined at the practice, campaign, component, or patient level">
                <QuestionCircleOutlined />
              </Tooltip>
            </Typography.Text>
          }
          okText="Save"
          cancelText="Cancel"
          confirmLoading={updateNetworkMutationResult.loading}
          onCancel={() => {
            mergeVarForm.resetFields();
            setMergeVarModalVisible(false);
          }}
          onOk={mergeVarForm.submit}
        >
          <KeyValueForm
            form={mergeVarForm}
            onFinish={(vars) => {
              updateNetworkMutation({
                variables: {
                  network_id: selectedRecord!.network_id,
                  changes: { network_merge_vars: vars },
                },
              }).then(() => setMergeVarModalVisible(false));
            }}
          />
        </Modal>
      </Space>
    </>
  );
};

export default NetworksAdminPage;
