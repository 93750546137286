import React, { useState, useEffect, useContext } from 'react';

import { Result, Button, Table, Row, Col, Tooltip, Input, Avatar } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { PaginationProps } from 'antd/lib/pagination';
import { PlusOutlined, StopOutlined, CheckOutlined } from '@ant-design/icons';

import { textToHighlight } from '../../components/utils/Highlight';
import UserDescription from './UserDescription';
import { UserMutationModal } from './UserForm';
import PracticeTag from '../practices/PracticeTag';
import NetworkTag from '../networks/NetworkTag';
import OrganizationTag from '../organizations/OrganizationTag';
import MutateButton from '../utils/MutateButton';
import { UserContext, MabelUserRole } from '../../contexts/UserContext';

import useConstant from 'use-constant';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { DEBOUNCE_MS } from '../autocompletes';

import {
  UserDetailsFragment,
  SearchUsersAdvancedDocument,
  useSearchUsersAdvancedLazyQuery,
  SearchUsersAdvancedQueryVariables,
  EnableUserDocument,
  DisableUserDocument,
  Order_By,
  Users_Bool_Exp,
  Maybe,
} from '../../graphql/generated';
import { getOperationAST } from 'graphql';

import md5 from 'md5';
import dayjs from 'dayjs';

interface UsersTableProps {
  initialQuery?: string;
  role?: MabelUserRole;
}

const UsersTable: React.FC<UsersTableProps> = ({ initialQuery, role }) => {
  const baseFilters: Maybe<Users_Bool_Exp>[] = [{ groups: { _has_key: role } }];
  if (role === 'organization_user') {
    baseFilters.push({ organization_id: { _is_null: false } });
  }
  if (role === 'network_user') {
    baseFilters.push({ network_id: { _is_null: false } });
  }
  if (role === 'practice_user') {
    baseFilters.push({ practice_id: { _is_null: false } });
  }

  const initialSearchParams: {
    variables: SearchUsersAdvancedQueryVariables;
    pagination: PaginationProps;
  } = {
    variables: {
      exact_query: initialQuery || null,
      substring_query: initialQuery ? `%${initialQuery}%` : null,
      additional_filters: [...baseFilters, { is_disabled: { _in: [false] } }],
      offset: 0,
      limit: 10,
      order_by: [{ created_at: Order_By.Desc }],
    },
    pagination: {
      current: 1,
      pageSize: 10,
    },
  };
  const [searchParams, setSearchParams] = useState(initialSearchParams);
  const [
    searchUsersQuery,
    searchUsersQueryResults,
  ] = useSearchUsersAdvancedLazyQuery({
    variables: initialSearchParams.variables,
  });
  const searchUsersDebounced = useConstant(() =>
    AwesomeDebouncePromise(
      (searchParams: SearchUsersAdvancedQueryVariables) => {
        return searchUsersQuery({ variables: searchParams });
      },
      DEBOUNCE_MS,
      { key: (fieldId, _) => fieldId }
    )
  );
  useEffect(() => {
    searchUsersDebounced(searchParams.variables);
  }, [searchParams, searchUsersDebounced]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<
    UserDetailsFragment | undefined
  >();
  const { user } = useContext(UserContext);

  if (searchUsersQueryResults.error)
    return (
      <Result
        status="error"
        title="There was an error retrieving users"
        subTitle={searchUsersQueryResults.error?.message}
      />
    );

  const columns: ColumnsType<UserDetailsFragment> = [
    {
      dataIndex: 'photo',
      key: 'photo',
      width: 24,
      render: (text, record) => (
        <Avatar
          src={
            record.photo ||
            `https://www.gravatar.com/avatar/${md5(
              record.user_email.trim().toLowerCase()
            )}?d=404`
          }
          alt={'user avatar'}
          size="small"
        >
          {(record.user_name || record.user_email)
            .split(' ')
            .map((n: string) => n[0])
            .join('')
            .toUpperCase()}
        </Avatar>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'user_name',
      key: 'user_name',
      sorter: true,
      render: textToHighlight(searchParams.variables.exact_query),
    },
    {
      title: 'Email',
      dataIndex: 'user_email',
      key: 'user_email',
      sorter: true,
      render: textToHighlight(searchParams.variables.exact_query),
    },
    {
      title: 'MFA',
      dataIndex: 'preferred_mfa',
      key: 'preferred_mfa',
      render: (_, record) =>
        record.preferred_mfa === 'NOMFA' ? (
          <StopOutlined style={{ color: 'lightgrey' }} />
        ) : (
          <CheckOutlined className="success-color" />
        ),
    },
    {
      title: 'Last Login',
      dataIndex: 'last_login_at',
      key: 'last_login_at',
      sorter: true,
      render: (val) =>
        val ? dayjs(val).calendar(undefined, { sameElse: 'lll' }) : null,
    },
    {
      title: 'Enabled?',
      dataIndex: 'is_disabled',
      key: 'is_disabled',
      width: 1,
      align: 'center',
      sorter: true,
      filters: [
        {
          text: 'Enabled',
          value: 'false',
        },
        {
          text: 'Disabled',
          value: 'true',
        },
      ],
      defaultFilteredValue: ['false'],
      onFilter: (value, record) => record.is_disabled.toString() === value,
      render: (is_disabled) =>
        is_disabled ? (
          <Tooltip title="Users has been disabled">
            <StopOutlined className="danger-color" />
          </Tooltip>
        ) : (
          <CheckOutlined className="success-color" />
        ),
    },
    {
      key: 'edit',
      width: 1,
      align: 'center',
      render: (text, record) => {
        return record.is_disabled ? (
          <MutateButton
            document={EnableUserDocument}
            variables={{ user_id: record.user_id }}
            refetchQueries={[
              getOperationAST(SearchUsersAdvancedDocument)?.name?.value || '',
            ]}
          >
            Restore
          </MutateButton>
        ) : (
          <div style={{ display: 'inline-flex' }}>
            <Button
              type="link"
              size="small"
              style={{ margin: 0 }}
              onClick={() => {
                setSelectedRecord(record);
                setShowUpdateModal(true);
              }}
            >
              Edit
            </Button>
            <MutateButton
              disabled={user?.claims?.sub === record.user_id}
              document={DisableUserDocument}
              variables={{ user_id: record.user_id }}
              refetchQueries={[
                getOperationAST(SearchUsersAdvancedDocument)?.name?.value || '',
              ]}
              popconfirm={{
                title: 'Disable this user?',
              }}
            >
              Disable
            </MutateButton>
          </div>
        );
      },
    },
  ];

  if (role === 'practice_user' || role === 'admin') {
    columns.splice(2, 0, {
      title: 'Practice',
      dataIndex: 'practice_id',
      key: 'practice_id',
      sorter: true,
      render: (_, record) =>
        record.practice ? <PracticeTag practice={record.practice} /> : null,
    });
  }
  if (role === 'network_user' || role === 'admin') {
    columns.splice(2, 0, {
      title: 'Network',
      dataIndex: 'network_id',
      key: 'network_id',
      sorter: true,
      render: (_, record) =>
        record.network ? <NetworkTag network={record.network} /> : null,
    });
  }
  if (role === 'organization_user' || role === 'admin') {
    columns.splice(2, 0, {
      title: 'Organization',
      dataIndex: 'organization_name',
      key: 'organization_id',
      sorter: true,
      render: (_, record) =>
        record.organization ? (
          <OrganizationTag organization={record.organization} />
        ) : null,
    });
  }

  return (
    <>
      <Row gutter={[8, 8]} justify="center">
        <Col flex="auto">
          <Input.Search
            defaultValue={initialQuery || undefined}
            placeholder="Search by user name or email"
            enterButton
            allowClear
            loading={searchUsersQueryResults.loading}
            onSearch={(value) => {
              const trimmedValue = value;
              const newParams = {
                exact_query: trimmedValue,
                substring_query: trimmedValue ? `%${trimmedValue}%` : null,
                offset: 0,
              };
              setSearchParams((old) => {
                return {
                  variables: { ...old.variables, ...newParams },
                  pagination: { ...old.pagination, current: 1 },
                };
              });
            }}
            onChange={(e) => {
              const trimmedValue = e.target.value.trim();
              const newParams = {
                exact_query: trimmedValue,
                substring_query: trimmedValue ? `%${trimmedValue}%` : null,
                offset: 0,
              };
              setSearchParams((old) => {
                return {
                  variables: { ...old.variables, ...newParams },
                  pagination: { ...old.pagination, current: 1 },
                };
              });
            }}
          />
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setShowCreateModal(true)}
          >
            Add
            {role === 'practice_user'
              ? ' Practice '
              : role === 'network_user'
              ? ' Network '
              : role === 'organization_user'
              ? ' Organization '
              : ' Admin '}
            User
          </Button>
          <UserMutationModal
            formName="Create"
            visible={showCreateModal}
            userRole={role || 'practice_user'}
            onCancel={() => setShowCreateModal(false)}
            afterSubmit={() => setShowCreateModal(false)}
          />
          <UserMutationModal
            formName="Update"
            initialRecord={selectedRecord}
            visible={showUpdateModal}
            userRole={role || 'practice_user'}
            onCancel={() => setShowUpdateModal(false)}
            afterSubmit={() => setShowUpdateModal(false)}
          />
        </Col>
      </Row>
      <Row>
        <Col flex="auto">
          <Table<UserDetailsFragment>
            size="small"
            rowKey="user_id"
            loading={searchUsersQueryResults.loading}
            dataSource={searchUsersQueryResults.data?.users}
            pagination={{
              ...searchParams.pagination,
              position: ['bottomRight'],
              showSizeChanger: true,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              total:
                searchUsersQueryResults.data?.users_aggregate.aggregate
                  ?.count || 0,
            }}
            columns={columns}
            showSorterTooltip={false}
            onChange={(pagination, filters, sorter, extra) => {
              if (extra.action === 'sort') {
                const new_order = !sorter
                  ? []
                  : (sorter instanceof Array ? sorter : [sorter])
                      .filter((c) => c.columnKey && c.order)
                      .map((column) => {
                        const key = column.columnKey?.toString() || '';
                        const order =
                          column.order === 'ascend'
                            ? Order_By.AscNullsFirst
                            : Order_By.DescNullsLast;
                        return { [key]: order };
                      });
                const newParams = {
                  order_by: !new_order.length
                    ? initialSearchParams.variables.order_by
                    : new_order,
                };
                setSearchParams((old) => {
                  return {
                    variables: { ...old.variables, ...newParams },
                    pagination: old.pagination,
                  };
                });
              } else if (extra.action === 'filter') {
                const additional_filters: any[] = [...baseFilters];
                for (const k in filters) {
                  if (filters[k]?.length) {
                    additional_filters.push({ [k]: { _in: filters[k] } });
                  }
                }
                const newParams = {
                  offset: 0,
                  additional_filters: additional_filters,
                };
                setSearchParams((old) => {
                  return {
                    variables: { ...old.variables, ...newParams },
                    pagination: { ...old.pagination, current: 1 },
                  };
                });
              } else if (extra.action === 'paginate') {
                const newParams = {
                  limit: pagination.pageSize,
                  offset:
                    (pagination.pageSize || 0) *
                    ((pagination.current || 0) - 1),
                };
                setSearchParams((old) => {
                  return {
                    variables: { ...old.variables, ...newParams },
                    pagination: {
                      ...old.pagination,
                      current: pagination.current || 1,
                      pageSize: pagination.pageSize || old.pagination.pageSize,
                    },
                  };
                });
              }
            }}
            expandable={{
              expandedRowRender: (record) => <UserDescription user={record} />,
            }}
          ></Table>
        </Col>
      </Row>
    </>
  );
};

export default UsersTable;
