import { defaultDataIdFromObject, IdGetter } from 'apollo-cache-inmemory';

const cacheIdFromObject: IdGetter = (object) => {
  if (!object || !object.__typename) {
    return defaultDataIdFromObject(object);
  }
  // For aggregation queries, the __typename of the response is generated by hasura.
  // Instead of being the name of a database table, it ends with one of these suffixes.
  // These objects don't have unique ID's, so we just use the default defaultDataIdFromObject
  // which will cache them according to the full query used to retrieve them.
  if (
    object.__typename.endsWith('_aggregate') ||
    object.__typename.endsWith('_aggregate_fields') ||
    object.__typename.endsWith('_max_fields') ||
    object.__typename.endsWith('_min_fields') ||
    object.__typename.endsWith('_sum_fields') ||
    object.__typename.endsWith('_mutation_response')
  ) {
    return defaultDataIdFromObject(object);
  }

  // For all other queries, the __typename of the response comes from the table name.
  // We consistently name our tables so that the table name is the same as the primary
  // key name + 's'. For instance, the 'practices' table has primary key 'practice_id',
  // the 'providers' table has primary key 'provider_id' and so on. Thus the cache id
  // for each object is simply `{table name}:{primary key}`
  if (object.__typename.endsWith('s')) {
    const singular_typename = object.__typename.slice(0, -1);

    // Special case tables where the primary key is compound
    const o = object as any;
    if (object.__typename === 'business_associate_agreements') {
      return `${singular_typename}:${o.external_id}:${o.external_source}`;
    }
    if (object.__typename === 'provider_employments') {
      return `${singular_typename}:${o.provider_id}:${o.practice_id}`;
    }
    if (object.__typename === 'provider_memberships') {
      return `${singular_typename}:${o.provider_id}:${o.network_id}`;
    }
    if (object.__typename === 'organization_networks') {
      return `${singular_typename}:${o.organization_id}:${o.network_id}`;
    }
    if (object.__typename === 'campaign_approvals') {
      return `${singular_typename}:${o.practice_id}:${o.campaign_template_id}`;
    }
    if (object.__typename === 'email_component_approvals') {
      return `${singular_typename}:${o.practice_id}:${o.campaign_template_id}:${o.campaign_email_component_id}`;
    }
    if (object.__typename === 'letter_component_approvals') {
      return `${singular_typename}:${o.practice_id}:${o.campaign_template_id}:${o.campaign_letter_component_id}`;
    }
    if (object.__typename === 'sms_component_approvals') {
      return `${singular_typename}:${o.practice_id}:${o.campaign_template_id}:${o.campaign_sms_component_id}`;
    }
    if (object.__typename === 'patient_roster_files') {
      return `${singular_typename}:${o.key}:${o.identity_id}:${o.level}:`;
    }
    if (object.__typename === 'insurance_counts') {
      return `${singular_typename}:${o.primary_insurance_payer_name}:${o.primary_insurance_is_null}:${o.secondary_insurance_payer_name}:${o.secondary_insurance_is_null}`;
    }
    if (object.__typename === 'insurance_segment_mappings') {
      return `${singular_typename}:${o.primary_insurance_payer_name}:${o.primary_insurance_is_null}:${o.secondary_insurance_payer_name}:${o.secondary_insurance_is_null}`;
    }
    if (object.__typename === 'component_tracking_ids') {
      return `${singular_typename}:${o.component_id}:${o.tracking_id}`;
    }

    if (object.__typename === 'patient_segment_labels') {
      return `${singular_typename}:${o.patient_id}`;
    }
    if (object.__typename === 'patient_alignment_labels') {
      return `${singular_typename}:${o.patient_id}`;
    }
    if (
      object.__typename === 'analytics_analytics_cms_active_dce_participants'
    ) {
      return `${o.cms_dce_participant_id}`;
    }

    // all other other objects where primary key is `{{singular_tablename}}_id`
    if (o[singular_typename + '_id']) {
      return `${singular_typename}:${o[singular_typename + '_id']}`;
    }
  }
  console.warn(`Can't find id for object type: ${object?.__typename}`);
  return defaultDataIdFromObject(object);
};

export { cacheIdFromObject };
