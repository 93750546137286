import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as Handlebars from 'handlebars';

import { Helmet } from 'react-helmet-async';
import {
  Typography,
  Result,
  Table,
  Row,
  Col,
  Descriptions,
  Form,
  Modal,
  Button,
  Input,
  Space,
  Tooltip,
  PaginationProps,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useForm } from 'antd/lib/form/Form';

import {
  Campaign_Email_Components_Insert_Input,
  Campaign_Letter_Components_Insert_Input,
  Campaign_Sms_Components_Insert_Input,
  useCreateCampaignTemplateMutation,
  useDeleteCampaignTemplateMutation,
  useGetAllCampaignTemplatePreviewsLazyQuery,
  CampaignTemplateDetailsFragment,
  SearchCampaignsAdvancedQueryVariables,
  Order_By,
  useSearchCampaignsAdvancedLazyQuery,
  SearchCampaignsAdvancedQuery,
  SearchCampaignsAdvancedDocument,
  ListCampaignTemplatesDocument,
  Component_Tracking_Ids,
} from '../graphql/generated';

import PracticeTag from '../components/practices/PracticeTag';
import NetworkTag from '../components/networks/NetworkTag';

import dayjs from 'dayjs';
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  MailOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  DownloadOutlined,
  MessageOutlined,
} from '@ant-design/icons';
import { PaperPlaneOutlined, MailBulkOutlined } from '../components/MabelIcons';
import {
  DEBOUNCE_MS,
  NetworkSearchSelect,
  PracticeSearchSelect,
} from '../components/autocompletes';
import { getOperationAST } from 'graphql';
import { getLobTemplatePDF } from '../components/templates/LetterTemplatePreview';
import { getMergeVars } from '../components/templates/TemplateUtils';
import JSZip from 'jszip';
import { useRequest } from 'ahooks';
import useConstant from 'use-constant';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { textToHighlight } from '../components/utils/Highlight';
import Papa from 'papaparse';

const { Title } = Typography;

const CampaignsAdminPage = () => {
  const initialQuery = null;

  const initialSearchParams: {
    variables: SearchCampaignsAdvancedQueryVariables;
    pagination: PaginationProps;
  } = {
    variables: {
      exact_query: initialQuery || null,
      substring_query: initialQuery ? `%${initialQuery}%` : null,
      additional_filters: [{ is_archived: { _in: [false] } }],
      offset: 0,
      limit: 25,
      order_by: [{ updated_at: Order_By.Desc }],
    },
    pagination: {
      current: 1,
      pageSize: 25,
    },
  };
  const [searchParams, setSearchParams] = useState(initialSearchParams);
  const [
    searchCampaignsQuery,
    searchCampaignsQueryResults,
  ] = useSearchCampaignsAdvancedLazyQuery({
    variables: initialSearchParams.variables,
  });
  const searchCampaignsDebounced = useConstant(() =>
    AwesomeDebouncePromise(
      (searchParams: SearchCampaignsAdvancedQueryVariables) => {
        return searchCampaignsQuery({ variables: searchParams });
      },
      DEBOUNCE_MS,
      { key: (fieldId, _) => fieldId }
    )
  );
  useEffect(() => {
    searchCampaignsDebounced(searchParams.variables);
  }, [searchParams, searchCampaignsDebounced]);

  const [form] = useForm();
  const [
    showAddCampaignTemplateModal,
    setShowAddCampaignTemplateModal,
  ] = useState(false);

  const [
    createCampaignTemplate,
    createCampaignTemplateResults,
  ] = useCreateCampaignTemplateMutation();

  const [deleteCampaignTemplate] = useDeleteCampaignTemplateMutation();
  const history = useHistory();

  if (searchCampaignsQueryResults.error) {
    return (
      <Result
        status="error"
        title="There was an error retrieving the request"
        subTitle={`${searchCampaignsQueryResults.error?.message}`}
      />
    );
  }
  const columns: ColumnsType<
    SearchCampaignsAdvancedQuery['campaign_templates'][0]
  > = [
    {
      title: 'Network',
      dataIndex: ['network', 'network_name'],
      key: 'network_id',
      sorter: true,
      //Filters must be in in the form {value: string, text: string | number | boolean}
      //As such, a filter on undefined/null does not work, so the string value null is used instead
      filters: searchCampaignsQueryResults.data?.networks.map((n) => ({
        value: n.network_id,
        text: n.network_name,
      })),
      onFilter: (value, record) => {
        //Filters cannot have value null/undefined, hence string value null is used with an explicit null check
        return value === record.network?.network_id;
      },
      render: (_, record) =>
        record.network && <NetworkTag network={record.network} />,
    },
    {
      title: 'Practice',
      dataIndex: ['practice', 'practice_name'],
      key: 'practice_id',
      sorter: true,
      //Filters must be in in the form {value: string, text: string | number | boolean}
      //As such, a filter on undefined/null does not work, so the string value null is used instead
      filters: searchCampaignsQueryResults.data?.practices.map((p) => ({
        value: p.practice_id,
        text: p.practice_name,
      })),

      onFilter: (value, record) => {
        //Filters cannot have value null/undefined, hence string value null is used with an explicit null check
        return value === record.practice?.practice_id;
      },
      render: (_, record) =>
        record.practice && <PracticeTag practice={record.practice} />,
    },
    {
      title: 'Name',
      dataIndex: 'campaign_name',
      sorter: true,
      render: textToHighlight(searchParams.variables.exact_query),
    },
    {
      title: 'Description',
      dataIndex: 'campaign_description',
      sorter: true,
      render: textToHighlight(searchParams.variables.exact_query),
    },
    {
      key: 'edit',
      render: (text, record) => (
        <>
          {record.campaign_approvals.length > 0 ? (
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() =>
                Modal.confirm({
                  title: 'This campaign has already been approved!',
                  icon: <ExclamationCircleOutlined />,
                  content: 'Are you sure you want to continue?',
                  okType: 'danger',
                  okText: 'Edit Campaign',
                  cancelText: 'Cancel',
                  maskClosable: true,
                  onOk() {
                    history.push(
                      `/campaign-manager/${record.campaign_template_id}`
                    );
                  },
                })
              }
            >
              Edit
            </Button>
          ) : (
            <Link to={`/campaign-manager/${record.campaign_template_id}`}>
              <Button type="link" icon={<EditOutlined />}>
                Edit
              </Button>
            </Link>
          )}
          |
          <Button
            type="link"
            onClick={() => {
              form.setFieldsValue({
                ...record,
                //Manually pull campaign components out to
                //avoid conflicts with IDs + unwanted inserts on relationships
                campaign_email_components: {
                  data: record.campaign_email_components.map(
                    (ec) =>
                      ({
                        email_template_id: ec.email_template_id,
                        patient_segment_id: ec.patient_segment_id,
                        send_datetime: ec.send_datetime,
                        is_archived: ec.is_archived,
                      } as Campaign_Email_Components_Insert_Input)
                  ),
                },
                campaign_letter_components: {
                  data: record.campaign_letter_components.map(
                    (lc) =>
                      ({
                        letter_template_id: lc.letter_template_id,
                        patient_segment_id: lc.patient_segment_id,
                        send_datetime: lc.send_datetime,
                        return_envelope_id: lc.return_envelope_id,
                        is_first_class: lc.is_first_class,
                        skip_undeliverable_addresses:
                          lc.skip_undeliverable_addresses,
                        is_archived: lc.is_archived,
                      } as Campaign_Letter_Components_Insert_Input)
                  ),
                },
                campaign_sms_components: {
                  data: record.campaign_sms_components.map(
                    (ec) =>
                      ({
                        sms_template_id: ec.sms_template_id,
                        patient_segment_id: ec.patient_segment_id,
                        send_datetime: ec.send_datetime,
                        is_archived: ec.is_archived,
                      } as Campaign_Sms_Components_Insert_Input)
                  ),
                },
              });
              setShowAddCampaignTemplateModal(true);
            }}
            icon={<CopyOutlined />}
          >
            Duplicate
          </Button>
          |
          {record.campaign_approvals.length > 0 ? (
            <Tooltip
              title={`This campaign has already been approved and cannot be deleted`}
            >
              <Button type="link" icon={<DeleteOutlined />} disabled>
                Delete
              </Button>
            </Tooltip>
          ) : (
            <Button
              danger
              icon={<DeleteOutlined />}
              type="link"
              onClick={() => {
                Modal.confirm({
                  title: 'Are you sure?',
                  content: 'Deleting a campaign cannot be undone',
                  okText: 'Delete',
                  onOk: () =>
                    deleteCampaignTemplate({
                      variables: {
                        campaign_template_id: record.campaign_template_id,
                      },
                      refetchQueries: [
                        getOperationAST(ListCampaignTemplatesDocument)?.name
                          ?.value || '',
                        getOperationAST(SearchCampaignsAdvancedDocument)?.name
                          ?.value || '',
                      ],
                      awaitRefetchQueries: true,
                    }),
                });
              }}
            >
              Delete
            </Button>
          )}
        </>
      ),
    },
  ];
  return (
    <>
      <Helmet>
        <title>Campaigns Admin | Mabel</title>
      </Helmet>
      <Title level={3}>
        <MailBulkOutlined /> Campaigns
      </Title>

      <Row gutter={[8, 8]} justify="center">
        <Col flex="auto">
          <Input.Search
            defaultValue={initialQuery || undefined}
            placeholder="Search by campaign name, description, or id"
            enterButton
            allowClear
            loading={searchCampaignsQueryResults.loading}
            onSearch={(value) => {
              const trimmedValue = value;
              const newParams = {
                exact_query: trimmedValue,
                substring_query: trimmedValue ? `%${trimmedValue}%` : null,
                offset: 0,
              };
              setSearchParams((old) => {
                return {
                  variables: { ...old.variables, ...newParams },
                  pagination: { ...old.pagination, current: 1 },
                };
              });
            }}
            onChange={(e) => {
              const trimmedValue = e.target.value.trim();
              const newParams = {
                exact_query: trimmedValue,
                substring_query: trimmedValue ? `%${trimmedValue}%` : null,
                offset: 0,
              };
              setSearchParams((old) => {
                return {
                  variables: { ...old.variables, ...newParams },
                  pagination: { ...old.pagination, current: 1 },
                };
              });
            }}
          />
        </Col>
        <Col>
          {' '}
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setShowAddCampaignTemplateModal(true);
            }}
          >
            Add Campaign
          </Button>
          <Modal
            visible={showAddCampaignTemplateModal}
            title="Create Campaign"
            onOk={() => form.submit()}
            onCancel={() => {
              setShowAddCampaignTemplateModal(false);
              form.resetFields();
            }}
            confirmLoading={createCampaignTemplateResults.loading}
          >
            <Form
              name="campaign_form"
              form={form}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              onFinish={(values) => {
                createCampaignTemplate({
                  variables: {
                    campaign_template: {
                      ...values,
                      network_id: values.network_id?.value,
                      practice_id: values.practice_id?.value,
                    },
                  },
                  refetchQueries: [
                    getOperationAST(ListCampaignTemplatesDocument)?.name
                      ?.value || '',
                    getOperationAST(SearchCampaignsAdvancedDocument)?.name
                      ?.value || '',
                  ],
                });
                setShowAddCampaignTemplateModal(false);
              }}
            >
              <Form.Item
                name="campaign_name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: 'Please provide a campaign name',
                  },
                ]}
              >
                <Input placeholder="Name" />
              </Form.Item>
              <Form.Item
                name="network_id"
                label="Network"
                dependencies={['practice_id']}
                rules={[
                  ({ getFieldValue }) => ({
                    validator: (_, value) => {
                      if (value && getFieldValue('practice_id')) {
                        return Promise.reject(
                          'A network and practice cannot both be provided'
                        );
                      } else if (!(value || getFieldValue('practice_id'))) {
                        return Promise.reject(
                          'Either a practice or network must be provided'
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <NetworkSearchSelect
                  allowClear
                  onChange={(value) => {
                    form?.setFieldsValue({ network_id: value });
                  }}
                />
              </Form.Item>
              <Form.Item
                name="practice_id"
                label="Practice"
                dependencies={['network_id']}
                rules={[
                  ({ getFieldValue }) => ({
                    validator: (_, value) => {
                      if (value && getFieldValue('network_id')) {
                        return Promise.reject(
                          'Both network and practice cannot be provided'
                        );
                      } else if (!(value || getFieldValue('network_id'))) {
                        return Promise.reject(
                          'Either a practice or network must be provided'
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <PracticeSearchSelect
                  allowClear
                  onChange={(value) => {
                    form?.setFieldsValue({ practice_id: value });
                  }}
                />
              </Form.Item>
              <Form.Item name="campaign_description" label="Description">
                <Input placeholder="Description" />
              </Form.Item>
              <Form.Item noStyle name="campaign_merge_vars" />
              <Form.Item noStyle name="campaign_email_components" />
              <Form.Item noStyle name="campaign_letter_components" />
              <Form.Item noStyle name="campaign_sms_components" />
            </Form>
          </Modal>
        </Col>
      </Row>
      <Col flex="auto">
        <Table<SearchCampaignsAdvancedQuery['campaign_templates'][0]>
          size="small"
          rowKey="campaign_template_id"
          loading={searchCampaignsQueryResults.loading}
          dataSource={searchCampaignsQueryResults.data?.campaign_templates}
          pagination={{
            ...searchParams.pagination,
            position: ['bottomRight'],
            showSizeChanger: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            total:
              searchCampaignsQueryResults.data?.campaign_templates_aggregate
                .aggregate?.count || 0,
          }}
          columns={columns}
          showSorterTooltip={false}
          onChange={(pagination, filters, sorter, extra) => {
            if (extra.action === 'sort') {
              const new_order = !sorter
                ? []
                : (sorter instanceof Array ? sorter : [sorter])
                    .filter((c) => c.field && c.order)
                    .map((column) => {
                      const key = column.field?.toString() || '';
                      const order =
                        column.order === 'ascend'
                          ? Order_By.AscNullsFirst
                          : Order_By.DescNullsLast;
                      return column.field instanceof Array
                        ? column.field.reduceRight(
                            (value: any, parent: any) => ({
                              [parent]: value,
                            }),
                            order
                          )
                        : { [key]: order };
                    });
              const newParams = {
                order_by: !new_order.length
                  ? initialSearchParams.variables.order_by
                  : new_order,
              };
              setSearchParams((old) => {
                return {
                  variables: { ...old.variables, ...newParams },
                  pagination: old.pagination,
                };
              });
            } else if (extra.action === 'filter') {
              const additional_filters: any[] = [];
              for (const k in filters) {
                if (filters[k]?.length) {
                  additional_filters.push({ [k]: { _in: filters[k] } });
                }
              }
              const newParams = {
                offset: 0,
                additional_filters: additional_filters,
              };
              setSearchParams((old) => {
                return {
                  variables: { ...old.variables, ...newParams },
                  pagination: { ...old.pagination, current: 1 },
                };
              });
            } else if (extra.action === 'paginate') {
              const newParams = {
                limit: pagination.pageSize,
                offset:
                  (pagination.pageSize || 0) * ((pagination.current || 0) - 1),
              };
              setSearchParams((old) => {
                return {
                  variables: { ...old.variables, ...newParams },
                  pagination: {
                    ...old.pagination,
                    current: pagination.current || 1,
                    pageSize: pagination.pageSize || old.pagination.pageSize,
                  },
                };
              });
            }
          }}
          expandable={{
            expandedRowRender: (record) => (
              <Space direction="vertical" size="large">
                <Descriptions
                  title={record.campaign_name}
                  layout="horizontal"
                  column={1}
                >
                  <Descriptions.Item label="Campaign ID">
                    {record.campaign_template_id}
                  </Descriptions.Item>
                  <Descriptions.Item label="Network">
                    {record.network?.network_name || <i>null</i>}
                  </Descriptions.Item>
                  <Descriptions.Item label="Practice">
                    {record.practice?.practice_name || <i>null</i>}
                  </Descriptions.Item>
                  <Descriptions.Item label="Description">
                    {record.campaign_description}
                  </Descriptions.Item>
                  <Descriptions.Item label="Merge Variables">
                    <span style={{ whiteSpace: 'pre' }}>
                      {JSON.stringify(record.campaign_merge_vars, null, 4)}
                    </span>
                  </Descriptions.Item>
                </Descriptions>
                <Table<
                  SearchCampaignsAdvancedQuery['campaign_templates'][0]['campaign_letter_components'][0]
                >
                  size="small"
                  rowKey="campaign_letter_component_id"
                  bordered
                  title={() => (
                    <Typography.Title level={5}>
                      <PaperPlaneOutlined /> Letter Components
                    </Typography.Title>
                  )}
                  pagination={false}
                  dataSource={record.campaign_letter_components.filter(
                    (c) => !c.is_archived
                  )}
                >
                  <Table.Column<
                    SearchCampaignsAdvancedQuery['campaign_templates'][0]['campaign_letter_components'][0]
                  >
                    title="Send Date"
                    dataIndex="send_datetime"
                    render={(value) => dayjs(value).format('lll')}
                    sorter={(a, b) =>
                      a.send_datetime.localeCompare(b.send_datetime)
                    }
                  />
                  <Table.Column<
                    SearchCampaignsAdvancedQuery['campaign_templates'][0]['campaign_letter_components'][0]
                  >
                    title="Template"
                    dataIndex={['letter_template', 'letter_template_name']}
                    sorter={(a, b) =>
                      (
                        a.letter_template.letter_template_name || ''
                      ).localeCompare(
                        b.letter_template.letter_template_name || ''
                      )
                    }
                  />
                  <Table.Column<
                    SearchCampaignsAdvancedQuery['campaign_templates'][0]['campaign_letter_components'][0]
                  >
                    title="Patient Segment"
                    dataIndex={[
                      'patient_segment',
                      'patient_segment_description',
                    ]}
                    sorter={(a, b) =>
                      a.patient_segment.patient_segment_description.localeCompare(
                        b.patient_segment.patient_segment_description
                      )
                    }
                  />
                  <Table.Column<
                    SearchCampaignsAdvancedQuery['campaign_templates'][0]['campaign_letter_components'][0]
                  >
                    title="Tracking IDs"
                    dataIndex={['tracking_ids']}
                    render={(value: Component_Tracking_Ids[]) =>
                      value.map((x) => x.tracking_id).join(', ')
                    }
                  />
                </Table>
                <Table<
                  SearchCampaignsAdvancedQuery['campaign_templates'][0]['campaign_email_components'][0]
                >
                  size="small"
                  rowKey="campaign_email_component_id"
                  bordered
                  title={() => (
                    <Typography.Title level={5}>
                      <MailOutlined /> Email Components
                    </Typography.Title>
                  )}
                  pagination={false}
                  dataSource={record.campaign_email_components.filter(
                    (c) => !c.is_archived
                  )}
                >
                  <Table.Column<
                    SearchCampaignsAdvancedQuery['campaign_templates'][0]['campaign_email_components'][0]
                  >
                    title="Send Date"
                    dataIndex="send_datetime"
                    render={(value) => dayjs(value).format('lll')}
                    sorter={(a, b) =>
                      a.send_datetime.localeCompare(b.send_datetime)
                    }
                  />
                  <Table.Column<
                    SearchCampaignsAdvancedQuery['campaign_templates'][0]['campaign_email_components'][0]
                  >
                    title="Template"
                    dataIndex={['email_template', 'email_template_name']}
                    sorter={(a, b) =>
                      (
                        a.email_template.email_template_name || ''
                      ).localeCompare(
                        b.email_template.email_template_name || ''
                      )
                    }
                  />
                  <Table.Column<
                    SearchCampaignsAdvancedQuery['campaign_templates'][0]['campaign_email_components'][0]
                  >
                    title="Patient Segment"
                    dataIndex={[
                      'patient_segment',
                      'patient_segment_description',
                    ]}
                    sorter={(a, b) =>
                      a.patient_segment.patient_segment_description.localeCompare(
                        b.patient_segment.patient_segment_description
                      )
                    }
                  />
                  <Table.Column<
                    SearchCampaignsAdvancedQuery['campaign_templates'][0]['campaign_email_components'][0]
                  >
                    title="Tracking IDs"
                    dataIndex={['tracking_ids']}
                    render={(value: Component_Tracking_Ids[]) =>
                      value.map((x) => x.tracking_id).join(', ')
                    }
                  />
                </Table>
                <Table<
                  SearchCampaignsAdvancedQuery['campaign_templates'][0]['campaign_sms_components'][0]
                >
                  size="small"
                  rowKey="campaign_sms_component_id"
                  bordered
                  title={() => (
                    <Typography.Title level={5}>
                      <MessageOutlined /> SMS Components
                    </Typography.Title>
                  )}
                  pagination={false}
                  dataSource={record.campaign_sms_components.filter(
                    (c) => !c.is_archived
                  )}
                >
                  <Table.Column<
                    SearchCampaignsAdvancedQuery['campaign_templates'][0]['campaign_sms_components'][0]
                  >
                    title="Send Date"
                    dataIndex="send_datetime"
                    render={(value) => dayjs(value).format('lll')}
                    sorter={(a, b) =>
                      a.send_datetime.localeCompare(b.send_datetime)
                    }
                  />
                  <Table.Column<
                    SearchCampaignsAdvancedQuery['campaign_templates'][0]['campaign_sms_components'][0]
                  >
                    title="Template"
                    dataIndex={['sms_template', 'sms_template_name']}
                    sorter={(a, b) =>
                      (a.sms_template.sms_template_name || '').localeCompare(
                        b.sms_template.sms_template_name || ''
                      )
                    }
                  />
                  <Table.Column<
                    SearchCampaignsAdvancedQuery['campaign_templates'][0]['campaign_sms_components'][0]
                  >
                    title="Patient Segment"
                    dataIndex={[
                      'patient_segment',
                      'patient_segment_description',
                    ]}
                    sorter={(a, b) =>
                      a.patient_segment.patient_segment_description.localeCompare(
                        b.patient_segment.patient_segment_description
                      )
                    }
                  />
                  <Table.Column<
                    SearchCampaignsAdvancedQuery['campaign_templates'][0]['campaign_sms_components'][0]
                  >
                    title="Tracking IDs"
                    dataIndex={['tracking_ids']}
                    render={(value: Component_Tracking_Ids[]) =>
                      value.map((x) => x.tracking_id).join(', ')
                    }
                  />
                </Table>
                <CampaignTemplatesPreviewGenerator
                  campaign_template_id={record.campaign_template_id}
                />
              </Space>
            ),
          }}
        ></Table>
      </Col>
    </>
  );
};

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

async function processInBatches<T, U>(
  items: T[],
  worker: (item: T) => Promise<U>,
  batchSize: number,
  waitTime: number
): Promise<U[]> {
  let result: U[] = [];
  for (let i = 0; i < items.length; i += batchSize) {
    const batch = items.slice(i, i + batchSize);
    result = [...result, ...(await Promise.all(batch.map(worker)))];
    if (i + batchSize < items.length) {
      await delay(waitTime);
    }
  }
  return result;
}

const CampaignTemplatesPreviewGenerator: React.FC<{
  campaign_template_id: string;
}> = ({ campaign_template_id }) => {
  const getValidFilename = (s: string) => s.replace(/[\\/:"*?<>|]/g, '-');

  const getTemplatesForPractice = async (
    campaign: CampaignTemplateDetailsFragment,
    practice_id: string
  ) => {
    const uniqueLetterTemplates = Object.fromEntries(
      campaign.campaign_letter_components
        .filter((comp) => !comp.is_archived)
        .map((comp) => [comp.letter_template_id, comp])
    );
    const uniqueEmailTemplates = Object.fromEntries(
      campaign.campaign_email_components
        .filter((comp) => !comp.is_archived)
        .map((comp) => [comp.email_template_id, comp])
    );
    const uniqueSMSTemplates = Object.fromEntries(
      campaign.campaign_sms_components
        .filter((comp) => !comp.is_archived)
        .map((comp) => [comp.sms_template_id, comp])
    );
    const [letters, emails, sms_messages] = await Promise.all([
      Promise.all(
        Object.entries(uniqueLetterTemplates).map(async ([_, lc], index) => ({
          name: getValidFilename(
            `${lc.letter_template.letter_template_name}_${index + 1}`
          ),
          data: await getLobTemplatePDF(
            lc.letter_template.external_id,
            lc.letter_template.external_secondary_id,
            lc.letter_template.size,
            lc.letter_template.format,
            lc.letter_template.letter_template_id,
            practice_id,
            lc.campaign_letter_component_id
          ),
          short_url: await getMergeVars(
            'letter',
            lc.campaign_letter_component_id,
            practice_id
          ).then((vars) => vars['signature_url']),
        }))
      ),
      Promise.all(
        Object.entries(uniqueEmailTemplates).map(async ([_, ec], index) => {
          const vars = await getMergeVars(
            'email',
            ec.campaign_email_component_id,
            practice_id
          );
          return {
            name: getValidFilename(
              `${ec.email_template.email_template_name}_${index + 1}`
            ),
            data: Handlebars.compile(ec.email_template.body_html || '')(vars),
            short_url: vars['signature_url'],
          };
        })
      ),
      Promise.all(
        Object.entries(uniqueSMSTemplates).map(async ([_, ec], index) => {
          const vars = await getMergeVars(
            'sms',
            ec.campaign_sms_component_id,
            practice_id
          );
          return {
            name: getValidFilename(
              `${ec.sms_template.sms_template_name}_${index + 1}`
            ),
            data: Handlebars.compile(ec.sms_template.body_text || '')(vars),
            short_url: vars['signature_url'],
          };
        })
      ),
    ]);
    return [letters, emails, sms_messages];
  };

  const [
    campaignDetailsQuery,
    campaignDetailsQueryResult,
  ] = useGetAllCampaignTemplatePreviewsLazyQuery();

  const downloadPracticeTemplatesRequest = useRequest(
    async () => {
      const campaign =
        campaignDetailsQueryResult?.data?.campaign_templates_by_pk;
      const practices = campaign!.practice
        ? [campaign!.practice]
        : campaign!.network?.practices || [];

      // Get templates in batches because short.io has a rate limit
      // and Lob template preview generation is slow
      const practiceTemplates = await processInBatches(
        practices,
        (p) => getTemplatesForPractice(campaign!, p.practice_id),
        25,
        1000
      );

      const jszip = new JSZip();
      const campaignFolder = jszip.folder(
        getValidFilename(campaign!.campaign_name)
      );

      practiceTemplates.forEach(([letters, emails, sms_messages], i) => {
        const practiceFolder = campaignFolder?.folder(
          getValidFilename(`${practices[i].practice_name}_${i + 1}`)
        );
        if (emails.length > 0) {
          const emailFolder = practiceFolder?.folder('emails');
          emails.forEach((em: any) => {
            emailFolder?.file(`${em.name}.html`, em.data);
          });
        }
        if (letters.length > 0) {
          const letterFolder = practiceFolder?.folder('letters');
          letters.forEach((lt: any) => {
            if (lt.data.back) {
              letterFolder?.file(`${lt.name} (Front).pdf`, lt.data.front);
              letterFolder?.file(`${lt.name} (Back).pdf`, lt.data.back);
            } else {
              letterFolder?.file(`${lt.name}.pdf`, lt.data.front);
            }
          });
        }
        if (sms_messages.length > 0) {
          const smsFolder = practiceFolder?.folder('sms_messages');
          sms_messages.forEach((em: any) => {
            smsFolder?.file(`${em.name}.text`, em.data);
          });
        }
      });

      // Extract all short_urls , grouped by letter template name
      const short_urls = practiceTemplates.reduce(
        // accumulate into an object mapping name to [practice_name, short_url] pairs
        (
          acc: { [key: string]: string[][] },
          [letters, emails, sms_messages],
          i
        ) => {
          letters.forEach((lt: any) => {
            if (lt.short_url) {
              acc[lt.name] = acc[lt.name] || [];
              acc[lt.name].push([
                lt.short_url,
                practices[i].practice_name,
                practices[i].practice_address,
                practices[i].practice_description,
                practices[i].practice_id,
              ]);
            }
          });
          emails.forEach((em: any) => {
            if (em.short_url) {
              acc[em.name] = acc[em.name] || [];
              acc[em.name].push([
                em.short_url,
                practices[i].practice_name,
                practices[i].practice_address,
                practices[i].practice_description,
                practices[i].practice_id,
              ]);
            }
          });
          sms_messages.forEach((em: any) => {
            if (em.short_url) {
              acc[em.name] = acc[em.name] || [];
              acc[em.name].push([
                em.short_url,
                practices[i].practice_name,
                practices[i].practice_address,
                practices[i].practice_description,
                practices[i].practice_id,
              ]);
            }
          });
          return acc;
        },
        {}
      );
      Object.entries(short_urls).forEach(([template_name, urls]) => {
        // sort array by practice name and add header row
        urls.sort((a, b) => a[1].localeCompare(b[1]));
        urls.unshift([
          'short_url',
          'practice_name',
          'practice_address',
          'practice_description',
          'practice_id',
        ]);
        // create a csv file for each template name using papaparse
        const csv = Papa.unparse(urls, { header: true });
        campaignFolder?.file(`${template_name}_short_urls.csv`, csv);
      });

      const blob = await jszip.generateAsync({ type: 'blob' });
      saveAs(blob, getValidFilename(campaign!.campaign_name));
    },
    {
      ready: Boolean(campaignDetailsQueryResult?.data),
    }
  );

  return (
    <Button
      type="primary"
      icon={<DownloadOutlined />}
      onClick={() =>
        campaignDetailsQuery({
          variables: { campaign_template_id: campaign_template_id },
        })
      }
      loading={downloadPracticeTemplatesRequest.loading}
    >
      Download Previews for All Practices
    </Button>
  );
};

export default CampaignsAdminPage;
