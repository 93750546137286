import React, { useState, useEffect } from 'react';

import { Form, Input, Modal, Button, Select, InputNumber } from 'antd';
import { FormProps } from 'antd/lib/form';
import { useForm } from 'antd/lib/form/Form';
import { Store } from 'antd/lib/form/interface';
import {
  LetterTemplateBasicFragment,
  Letter_Format_Types_Enum,
  ListLetterTemplatesDocument,
  SearchLetterTemplatesDocument,
  useCreateLetterTemplateMutation,
  useUpdateLetterTemplateMutation,
} from '../../graphql/generated';
import { getOperationAST } from 'graphql';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import * as Handlebars from 'handlebars';

interface LetterTemplateFormProps extends FormProps {
  initialLetterTemplate?: LetterTemplateBasicFragment;
  initialValues?: Partial<LetterTemplateBasicFragment>;

  updateMutation?: ReturnType<typeof useUpdateLetterTemplateMutation>[0];
  createMutation?: ReturnType<typeof useCreateLetterTemplateMutation>[0];
  showSubmit?: boolean;
  haveValuesChanged?: boolean;
  setHaveValuesChanged?: React.Dispatch<React.SetStateAction<boolean>>;
}

const LetterTemplateForm: React.FC<LetterTemplateFormProps> = ({
  form,
  initialLetterTemplate,
  initialValues,

  updateMutation,
  createMutation,
  showSubmit,
  haveValuesChanged,
  setHaveValuesChanged,
  ...rest
}) => {
  const [formInternal] = useForm(form);

  const [
    updateMutationInternal,
    updateMutationInternalResults,
  ] = useUpdateLetterTemplateMutation();
  const [
    createMutationInternal,
    createMutationInternalResults,
  ] = useCreateLetterTemplateMutation();

  useEffect(() => {
    formInternal.resetFields();
    formInternal.setFieldsValue(
      recordToFormVals(
        initialLetterTemplate ||
          initialValues ||
          //Use letter is used as the default value for format
          ({
            letter_from: '{{practice_name}}',
            letter_company: '{{practice_company}}',
            format: 'LETTER',
            size: 'us_letter',
            num_pages: 1,
            is_color: true,
            perforated_page: null,
            is_public: false,
          } as Partial<LetterTemplateBasicFragment>)
      )
    );
  }, [formInternal, initialLetterTemplate, initialValues]);

  const recordToFormVals = (record: Partial<LetterTemplateBasicFragment>) => {
    return {
      ...record,
      //Although the DB is fine with null value sizes,
      //force one to be displayed in the form, as it's a bit nicer UX
      size: !record.size
        ? record.format === 'POSTCARD'
          ? '6x9'
          : record.format === 'SELF_MAILER'
          ? '6x18_bifold'
          : 'us_letter'
        : record.size,
    };
  };

  const formValsToRecordChanges = (formValues: Store) => {
    return initialLetterTemplate
      ? Object.fromEntries(
          Object.entries(formValues).filter(
            ([k, v]) => (initialLetterTemplate as any)[k] !== v
          )
        )
      : formValues;
  };

  const valuesHaveChanged = (formValues: Store) =>
    Object.keys(formValsToRecordChanges(formValues)).length > 0;

  const handleInsert = (values: any) => {
    (createMutation || createMutationInternal)({
      variables: {
        letter_template: { ...formValsToRecordChanges(values) },
      } as any,
      refetchQueries: [
        getOperationAST(ListLetterTemplatesDocument)?.name?.value || '',
        getOperationAST(SearchLetterTemplatesDocument)?.name?.value || '',
      ],
    });
  };

  const handleUpdate = (values: any) => {
    if (!initialLetterTemplate) {
      return;
    }
    const { ...changes } = formValsToRecordChanges(values);
    (updateMutation || updateMutationInternal)({
      variables: {
        letter_template_id: initialLetterTemplate.letter_template_id,
        changes: Object.keys(changes).length ? changes : undefined,
      },
      refetchQueries: [
        getOperationAST(ListLetterTemplatesDocument)?.name?.value || '',
        getOperationAST(SearchLetterTemplatesDocument)?.name?.value || '',
      ],
    });
    if (setHaveValuesChanged) setHaveValuesChanged(false);
  };
  return (
    <Form
      {...rest}
      form={formInternal}
      labelCol={rest.labelCol || { span: 6 }}
      wrapperCol={rest.wrapperCol || { span: 18 }}
      onValuesChange={(_, allValues) =>
        setHaveValuesChanged
          ? setHaveValuesChanged(valuesHaveChanged(allValues))
          : null
      }
      onFinish={initialLetterTemplate ? handleUpdate : handleInsert}
    >
      <Form.Item
        label="Template Name"
        name="letter_template_name"
        rules={[
          {
            required: true,
            message: 'Please provide a template name',
          },
        ]}
      >
        <Input placeholder="Template Name" />
      </Form.Item>
      <Form.Item
        label="From"
        name="letter_from"
        rules={[
          {
            validator: (_, value) => {
              try {
                if (value) {
                  Handlebars.compile(value)({});
                }
                return Promise.resolve();
              } catch (e) {
                return Promise.reject(new Error('Invalid Handlebars template'));
              }
            },
          },
        ]}
      >
        <Input placeholder="{{practice_name}}" />
      </Form.Item>
      <Form.Item
        label="Company"
        name="letter_company"
        rules={[
          {
            validator: (_, value) => {
              try {
                if (value) {
                  Handlebars.compile(value)({});
                }
                return Promise.resolve();
              } catch (e) {
                return Promise.reject(new Error('Invalid Handlebars template'));
              }
            },
          },
        ]}
      >
        <Input placeholder="{{practice_company}}" />
      </Form.Item>
      <Form.Item
        label="Template Type"
        name="format"
        rules={[
          {
            required: true,
            message: 'Please select a letter format',
          },
        ]}
      >
        <Select
          optionFilterProp="label"
          options={Object.values(Letter_Format_Types_Enum).map((k) => ({
            label: k,
            value: k,
          }))}
          onChange={(format) => {
            form?.setFieldsValue({
              ...form.getFieldsValue(),
              size:
                format === 'POSTCARD'
                  ? '6x9'
                  : format === 'SELF_MAILER'
                  ? '6x18_bifold'
                  : 'us_letter',
            });
          }}
        />
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prev, curr) => prev.format !== curr.format}
      >
        {/*
          This nested Form.Item is due to the dependency on format,
          it forces an update when the field does, otherwise the
          Select options won't re-render correctly
        */}
        {({ getFieldValue }) => {
          return (
            <Form.Item
              name="size"
              label="Size"
              rules={[
                {
                  required: true,
                  message: 'Please provide a size',
                },
              ]}
            >
              <Select
                optionFilterProp="label"
                options={
                  getFieldValue('format') === 'POSTCARD'
                    ? ['6x9', '4x6', '6x11'].map((s) => ({
                        label: s,
                        value: s,
                      }))
                    : getFieldValue('format') === 'SELF_MAILER'
                    ? [
                        '6x18_bifold',
                        '11x9_bifold',
                        '12x9_bifold',
                        '17.75x9_trifold',
                      ].map((s) => ({
                        label: s,
                        value: s,
                      }))
                    : [{ label: 'us_letter', value: 'us_letter' }]
                }
              />
            </Form.Item>
          );
        }}
      </Form.Item>
      <Form.Item
        label="External ID"
        name="external_id"
        rules={[{ required: true, message: 'Please provide an external ID' }]}
        help="The ID of the Lob template."
      >
        <Input placeholder="External ID" />
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prev, curr) => prev.format !== curr.format}
      >
        {/*
          This nested Form.Item is due to the dependency on format,
          it forces an update when the field does, otherwise the
          secondary ID field won't appear correctly
        */}
        {({ getFieldValue }) =>
          ['CHECK', 'POSTCARD', 'SELF_MAILER'].includes(
            getFieldValue('format')
          ) ? (
            <Form.Item
              label="External Back ID"
              name="external_secondary_id"
              help="For postcards, this is the back template. For self_mailers, the outside. For checks, the attachment."
              rules={[
                {
                  required: true,
                  message: 'Please provide an external secondary ID',
                },
              ]}
            >
              <Input placeholder="External Secondary ID" />
            </Form.Item>
          ) : null
        }
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prev, curr) => prev.format !== curr.format}
      >
        {({ getFieldValue }) =>
          getFieldValue('format') !== 'POSTCARD' &&
          getFieldValue('format') !== 'SELF_MAILER' ? (
            <Form.Item
              label="Page Count"
              name="num_pages"
              rules={[
                {
                  required: true,
                  message: 'Please specify the number of pages in the template',
                },
                {
                  type: 'integer',
                  message: 'Not an integer',
                },
              ]}
            >
              <InputNumber min={1} max={120} />
            </Form.Item>
          ) : null
        }
      </Form.Item>
      <Form.Item noStyle dependencies={['format', 'num_pages']}>
        {({ getFieldValue }) =>
          getFieldValue('format') === 'LETTER' ? (
            <Form.Item
              label="Perforated Page"
              name="perforated_page"
              help="The page number to be perforated. For use with return envelopes."
            >
              <InputNumber min={1} max={getFieldValue('num_pages')} />
            </Form.Item>
          ) : null
        }
      </Form.Item>
      <Form.Item name="is_color" label="Color" valuePropName="checked">
        <Checkbox />
      </Form.Item>
      <Form.Item
        name="webform_url"
        label="Webform URL"
        rules={[{ type: 'url' }]}
      >
        <Input placeholder="https://form.jotform.com/1234..." />
      </Form.Item>
      <Form.Item
        name="is_public"
        label="Public"
        valuePropName="checked"
        help="Allow organizations to view and generate themselves?"
      >
        <Checkbox />
      </Form.Item>
      {showSubmit ? (
        <Form.Item style={{ marginTop: 12 }} wrapperCol={{ offset: 6 }}>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!haveValuesChanged}
            loading={
              createMutationInternalResults.loading ||
              updateMutationInternalResults.loading
            }
          >
            Save
          </Button>
        </Form.Item>
      ) : null}
    </Form>
  );
};

interface LetterTemplateMutationModalProps {
  formName: string;
  initialRecord?: LetterTemplateBasicFragment;
  initialValues?: Partial<LetterTemplateBasicFragment>;
  visible: boolean;
  onCancel?: (() => void) | undefined;
  afterSubmit?: (() => void) | undefined;
}

const LetterTemplateMutationModal: React.FC<LetterTemplateMutationModalProps> = ({
  initialRecord,
  initialValues,
  visible,
  onCancel,
  afterSubmit,
}) => {
  const [form] = useForm();
  const [haveValuesChanged, setHaveValuesChanged] = useState(false);

  const [
    updateMutation,
    updateMutationResult,
  ] = useUpdateLetterTemplateMutation({
    onCompleted: () => {
      form.resetFields();
      if (afterSubmit) afterSubmit();
    },
  });
  const [
    createMutation,
    createMutationResult,
  ] = useCreateLetterTemplateMutation({
    onCompleted: () => {
      form.resetFields();
      //Force default field values for create mutation
      form.setFieldsValue({
        ...form.getFieldsValue(),
        letter_from: '{{practice_name}}',
        letter_company: '{{practice_company}}',
        format: 'LETTER',
        size: 'us_letter',
        num_pages: 1,
        is_color: true,
        is_public: false,
      });
      if (afterSubmit) afterSubmit();
    },
  });

  return (
    <Modal
      title={
        initialRecord ? 'Update Letter Template' : 'Create Letter Template'
      }
      okText={
        initialRecord ? 'Update Letter Template' : 'Create Letter Template'
      }
      okButtonProps={{ disabled: initialRecord && !haveValuesChanged }}
      visible={visible}
      onCancel={onCancel}
      onOk={(e) => form.submit()}
      confirmLoading={
        updateMutationResult.loading || createMutationResult.loading
      }
      width={650}
    >
      <LetterTemplateForm
        form={form}
        initialLetterTemplate={initialRecord}
        initialValues={initialValues}
        updateMutation={updateMutation}
        createMutation={createMutation}
        haveValuesChanged={haveValuesChanged}
        setHaveValuesChanged={setHaveValuesChanged}
      />
    </Modal>
  );
};

export { LetterTemplateForm, LetterTemplateMutationModal };
