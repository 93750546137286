import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import md5 from 'md5';

import { ReactComponent as MabelLogo } from '../images/Mabel_Logo_White_RGB.svg';

import { Avatar, Button, Layout, Menu, Dropdown, Space, Alert } from 'antd';
import { Auth } from 'aws-amplify';
import { UserContext, MabelUserRole } from '../contexts/UserContext';
import useWindowDimensions from '../hooks/useWindowDimensions';

import {
  TeamOutlined,
  BarChartOutlined,
  DownOutlined,
  LogoutOutlined,
  MenuOutlined,
  UserSwitchOutlined,
  SettingOutlined,
  FileTextOutlined,
  QuestionCircleOutlined,
  MailOutlined,
  ToolOutlined,
  SwapOutlined,
  ExclamationCircleOutlined,
  AuditOutlined,
} from '@ant-design/icons';

import { useGetUserDetailsQuery } from '../graphql/generated';

import {
  MailBulkOutlined,
  ClinicOutlined,
  NetworkOutlined,
  StethoscopeOutlined,
  UserInjuredOutlined,
  RocketOutlined,
  BuildingOutlined,
} from './MabelIcons';

import { useApolloClient } from '@apollo/react-hooks';
import { useSessionStorageState } from 'ahooks';
import SwitchRoleButton from './utils/SwitchRoleButton';

type Theme = 'light' | 'dark' | undefined;
const MabelNavBar = ({ theme = 'dark' }: { theme?: Theme }) => {
  const client = useApolloClient();
  const { user, setRole } = useContext(UserContext);
  const location = useLocation();
  const { width } = useWindowDimensions();

  const [, setUserRole] = useSessionStorageState('userRole', undefined);

  const practiceUserMenuItems = [
    <Menu.Item key="patients" icon={<UserInjuredOutlined />}>
      <Link to="/patients">Patients</Link>
    </Menu.Item>,
    <Menu.Item
      key="campaigns"
      icon={<MailBulkOutlined style={{ fontSize: 20 }} />}
    >
      <Link to="/campaigns">Campaigns</Link>
    </Menu.Item>,
    <Menu.Item key="analytics" icon={<BarChartOutlined />} disabled>
      <Link to="/analytics">Analytics (coming soon)</Link>
    </Menu.Item>,
  ];
  const networkUserMenuItems = [
    <Menu.Item key="providers" icon={<StethoscopeOutlined />}>
      <Link to="/providers">Providers</Link>
    </Menu.Item>,
  ];

  const organizationUserMenuItems = [
    <Menu.Item key="dashboards" icon={<BarChartOutlined />}>
      <Link to="/dashboards">Dashboards</Link>
    </Menu.Item>,
  ];

  const adminUserMenuItems = [
    <Menu.SubMenu key="configure" title="Configure" icon={<ToolOutlined />}>
      <Menu.Item
        key="campaigns"
        icon={<MailBulkOutlined style={{ fontSize: 20 }} />}
      >
        <Link to="/campaigns">Campaigns</Link>
      </Menu.Item>
      <Menu.Item key="templates" icon={<FileTextOutlined />}>
        <Link to="/templates">Templates</Link>
      </Menu.Item>
      <Menu.Item key="organizations" icon={<BuildingOutlined />}>
        <Link to="/organizations">Organizations</Link>
      </Menu.Item>
      <Menu.Item key="networks" icon={<NetworkOutlined />}>
        <Link to="/networks">Networks</Link>
      </Menu.Item>
      <Menu.Item key="practices" icon={<ClinicOutlined />}>
        <Link to="/practices">Practices</Link>
      </Menu.Item>
      <Menu.Item key="providers" icon={<StethoscopeOutlined />}>
        <Link to="/providers">Providers</Link>
      </Menu.Item>
      <Menu.Item key="patients" icon={<UserInjuredOutlined />}>
        <Link to="/patients">Patients</Link>
      </Menu.Item>
      <Menu.Item key="users" icon={<TeamOutlined />}>
        <Link to="/users">Users</Link>
      </Menu.Item>
    </Menu.SubMenu>,
    <Menu.Item key="insurance-mapping" icon={<SwapOutlined />}>
      <Link to="/insurance-mapping">Insurance Segmentation</Link>
    </Menu.Item>,
    <Menu.Item key="pvas" icon={<AuditOutlined />}>
      <Link to="/svas">SVA Processing</Link>
    </Menu.Item>,
    <Menu.Item key="health-equity-reports" icon={<AuditOutlined />}>
      <Link to="/health-equity-reports">HEDR Processing (Beta)</Link>
    </Menu.Item>,
  ];

  const alternateRoleSubmenu =
    (user?.allowed_roles || []).length > 1 ? (
      <Menu.SubMenu icon={<UserSwitchOutlined />} title="Switch Role">
        {user?.allowed_roles?.sort().map((role) => {
          return (
            <Menu.Item
              key={role}
              onClick={(event) => {
                setRole(event.key as MabelUserRole);
                client.clearStore();
              }}
            >
              {role}
            </Menu.Item>
          );
        })}
      </Menu.SubMenu>
    ) : (
      <></>
    );

  const navMenuItems = () => {
    return user?.role === 'network_user'
      ? networkUserMenuItems
      : user?.role === 'organization_user'
      ? organizationUserMenuItems
      : user?.role === 'practice_user'
      ? practiceUserMenuItems
      : user?.role === 'admin'
      ? adminUserMenuItems
      : [];
  };
  const collapsedNav = () => (
    <Dropdown
      overlayStyle={{ fontSize: 16 }}
      key="collapsed"
      overlay={
        <Menu
          theme={theme}
          selectedKeys={location.pathname.split('/').concat([user?.role || ''])}
        >
          {navMenuItems()}
        </Menu>
      }
    >
      <Button ghost icon={<MenuOutlined />} />
    </Dropdown>
  );

  const { data } = useGetUserDetailsQuery({
    variables: { user_id: user?.claims?.sub },
  });
  const userOrganization =
    user?.role === 'admin'
      ? 'Admin'
      : user?.role === 'practice_user' && data?.users_by_pk?.practice
      ? data?.users_by_pk?.practice.practice_name
      : user?.role === 'network_user' && data?.users_by_pk?.network
      ? data?.users_by_pk?.network.network_name
      : user?.role === 'organization_user' && data?.users_by_pk?.organization
      ? data?.users_by_pk?.organization.organization_name
      : null;
  const bannerMessage = `You are logged in as a member of ${
    user?.role === 'admin'
      ? 'Admin'
      : user?.role === 'practice_user' && data?.users_by_pk?.practice
      ? [
          data?.users_by_pk?.practice.practice_name,
          data?.users_by_pk?.practice.practice_description,
          data?.users_by_pk?.practice.practice_id,
        ]
          .filter(Boolean)
          .join(', ')
      : user?.role === 'network_user' && data?.users_by_pk?.network
      ? data?.users_by_pk?.network.network_name
      : user?.role === 'organization_user' && data?.users_by_pk?.organization
      ? data?.users_by_pk?.organization.organization_name
      : null
  }`;
  return (
    <Layout.Header
      style={{
        position: 'fixed',
        zIndex: 1,
        width: '100%',
        padding: '0',
      }}
    >
      {user?.allowed_roles?.includes('admin') && user?.role !== 'admin' && (
        <Alert
          banner
          message={bannerMessage}
          type="error"
          showIcon
          icon={<ExclamationCircleOutlined />}
          closable
          action={
            <SwitchRoleButton
              changeRole="admin"
              buttonText="Switch back to admin"
            />
          }
        />
      )}
      <div className="header-left">
        <Link to="/">
          <MabelLogo />
        </Link>
      </div>
      <Menu
        theme={theme}
        mode="horizontal"
        selectedKeys={location.pathname.split('/').concat([user?.role || ''])}
      >
        {width < 992 ? [collapsedNav()] : navMenuItems()}
        <Menu.SubMenu
          style={{ float: 'right', marginRight: 24 }}
          title={
            <div style={{ marginTop: -4 }}>
              <Button type="text" className="text-color-secondary-dark">
                <Space>
                  <Avatar
                    src={
                      data?.users_by_pk?.photo ||
                      user?.claims?.picture ||
                      user?.claims?.photo ||
                      `https://www.gravatar.com/avatar/${md5(
                        user?.claims?.email.trim().toLowerCase()
                      )}?d=404`
                    }
                    alt={'user avatar'}
                    size="small"
                  >
                    {(user?.claims?.name || user?.claims?.email)
                      .split(' ')
                      .map((n: string) => n[0])
                      .join('')
                      .toUpperCase()}
                  </Avatar>
                  {width >= 576 && user?.claims?.email}
                  <DownOutlined />
                </Space>
                <br />
                <small style={{ position: 'relative', top: -4 }}>
                  {width >= 576 && userOrganization}
                </small>
              </Button>
            </div>
          }
        >
          {width < 576 && (
            <Menu.ItemGroup
              title={`${user?.claims?.email} (${userOrganization})`}
            />
          )}
          <Menu.Item key="settings" icon={<SettingOutlined />}>
            <Link to="/settings">Settings</Link>
          </Menu.Item>
          {user?.role === 'practice_user' && (
            <Menu.Item key="onboarding" icon={<RocketOutlined />}>
              <Link to="/onboarding">Practice Onboarding</Link>
            </Menu.Item>
          )}
          {alternateRoleSubmenu}
          <Menu.Item
            key="logout"
            icon={<LogoutOutlined />}
            onClick={() => {
              Auth.signOut().then(() => {
                setUserRole(undefined);
                client.clearStore();
              });
            }}
          >
            Logout
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu
          style={{ float: 'right' }}
          title="Help"
          icon={<QuestionCircleOutlined />}
        >
          <Menu.Item key="email_support" icon={<MailOutlined />}>
            <a
              href="mailto:support@getmabel.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Email Support
            </a>
          </Menu.Item>
        </Menu.SubMenu>
      </Menu>
    </Layout.Header>
  );
};

export default MabelNavBar;
