import React, { useState, useEffect } from 'react';

import { Form, Input, Modal, Button } from 'antd';
import { FormProps } from 'antd/lib/form';
import { useForm } from 'antd/lib/form/Form';
import { Store } from 'antd/lib/form/interface';
import {
  EmailTemplateBasicFragment,
  ListEmailTemplatesDocument,
  SearchEmailTemplatesDocument,
  useCreateEmailTemplateMutation,
  useUpdateEmailTemplateMutation,
} from '../../graphql/generated';
import { getOperationAST } from 'graphql';
import * as Handlebars from 'handlebars';

interface EmailTemplateFormProps extends FormProps {
  initialEmailTemplate?: EmailTemplateBasicFragment;
  initialValues?: Partial<EmailTemplateBasicFragment>;

  updateMutation?: ReturnType<typeof useUpdateEmailTemplateMutation>[0];
  createMutation?: ReturnType<typeof useCreateEmailTemplateMutation>[0];
  showSubmit?: boolean;
  haveValuesChanged?: boolean;
  setHaveValuesChanged?: React.Dispatch<React.SetStateAction<boolean>>;
}

const EmailTemplateForm: React.FC<EmailTemplateFormProps> = ({
  form,
  initialEmailTemplate,
  initialValues,
  updateMutation,
  createMutation,
  showSubmit,
  haveValuesChanged,
  setHaveValuesChanged,
  ...rest
}) => {
  const [formInternal] = useForm(form);
  const [
    updateMutationInternal,
    updateMutationInternalResults,
  ] = useUpdateEmailTemplateMutation();
  const [
    createMutationInternal,
    createMutationInternalResults,
  ] = useCreateEmailTemplateMutation();

  useEffect(() => {
    formInternal.resetFields();
    formInternal.setFieldsValue(
      initialEmailTemplate ||
        initialValues ||
        ({
          email_from: '{{practice_name}}',
        } as Partial<EmailTemplateBasicFragment>)
    );
  }, [formInternal, initialEmailTemplate, initialValues]);
  const formValsToRecordChanges = (formValues: Store) => {
    return initialEmailTemplate
      ? Object.fromEntries(
          Object.entries(formValues).filter(
            ([k, v]) => (initialEmailTemplate as any)[k] !== v
          )
        )
      : formValues;
  };

  const valuesHaveChanged = (formValues: Store) =>
    Object.keys(formValsToRecordChanges(formValues)).length > 0;

  const handleInsert = (values: any) => {
    (createMutation || createMutationInternal)({
      variables: {
        email_template: { ...formValsToRecordChanges(values) },
      } as any,
      refetchQueries: [
        getOperationAST(ListEmailTemplatesDocument)?.name?.value || '',
        getOperationAST(SearchEmailTemplatesDocument)?.name?.value || '',
      ],
    });
  };

  const handleUpdate = (values: any) => {
    if (!initialEmailTemplate) {
      return;
    }
    const { ...changes } = formValsToRecordChanges(values);
    (updateMutation || updateMutationInternal)({
      variables: {
        email_template_id: initialEmailTemplate.email_template_id,
        changes: Object.keys(changes).length ? changes : undefined,
      },
      refetchQueries: [
        getOperationAST(ListEmailTemplatesDocument)?.name?.value || '',
        getOperationAST(SearchEmailTemplatesDocument)?.name?.value || '',
      ],
    });
    if (setHaveValuesChanged) setHaveValuesChanged(false);
  };

  return (
    <Form
      {...rest}
      form={formInternal}
      labelCol={rest.labelCol || { span: 6 }}
      wrapperCol={rest.wrapperCol || { span: 18 }}
      onValuesChange={(_, allValues) =>
        setHaveValuesChanged
          ? setHaveValuesChanged(valuesHaveChanged(allValues))
          : null
      }
      onFinish={initialEmailTemplate ? handleUpdate : handleInsert}
    >
      <Form.Item
        label="Template Name"
        name="email_template_name"
        rules={[
          {
            required: true,
            message: 'Please provide a template name',
          },
        ]}
      >
        <Input placeholder="Template Name" />
      </Form.Item>
      <Form.Item
        label="From"
        name="email_from"
        rules={[
          {
            validator: (_, value) => {
              try {
                if (value) {
                  Handlebars.compile(value)({});
                }
                return Promise.resolve();
              } catch (e) {
                return Promise.reject(new Error('Invalid Handlebars template'));
              }
            },
          },
        ]}
      >
        <Input placeholder="{{practice_name}}" />
      </Form.Item>
      <Form.Item
        label="Email Subject"
        name="email_subject"
        rules={[{ required: true, message: 'Please provide an email subject' }]}
      >
        <Input placeholder="Email Subject" />
      </Form.Item>
      <Form.Item label="Body HTML" name="body_html">
        <Input.TextArea
          placeholder="HTML"
          autoSize={{ minRows: 4, maxRows: 12 }}
        />
      </Form.Item>
      <Form.Item
        name="webform_url"
        label="Webform URL"
        rules={[{ type: 'url' }]}
      >
        <Input placeholder="https://form.jotform.com/1234..." />
      </Form.Item>
      {showSubmit ? (
        <Form.Item style={{ marginTop: 12 }} wrapperCol={{ offset: 6 }}>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!haveValuesChanged}
            loading={
              createMutationInternalResults.loading ||
              updateMutationInternalResults.loading
            }
          >
            Save
          </Button>
        </Form.Item>
      ) : null}
    </Form>
  );
};

interface EmailTemplateMutationModalProps {
  formName: string;
  initialRecord?: EmailTemplateBasicFragment;
  initialValues?: Partial<EmailTemplateBasicFragment>;
  visible: boolean;
  onCancel?: (() => void) | undefined;
  afterSubmit?: (() => void) | undefined;
}

const EmailTemplateMutationModal: React.FC<EmailTemplateMutationModalProps> = ({
  initialRecord,
  initialValues,
  visible,
  onCancel,
  afterSubmit,
}) => {
  const [form] = useForm();
  const [haveValuesChanged, setHaveValuesChanged] = useState(false);

  const [updateMutation, updateMutationResult] = useUpdateEmailTemplateMutation(
    {
      onCompleted: () => {
        form.resetFields();
        if (afterSubmit) afterSubmit();
      },
    }
  );
  const [createMutation, createMutationResult] = useCreateEmailTemplateMutation(
    {
      onCompleted: () => {
        form.resetFields();
        form.setFieldsValue({
          ...form.getFieldsValue(),
          email_from: '{{practice_name}}',
        });
        if (afterSubmit) afterSubmit();
      },
    }
  );
  return (
    <Modal
      title={initialRecord ? 'Update Email Template' : 'Create Email Template'}
      okText={initialRecord ? 'Update Email Template' : 'Create Email Template'}
      okButtonProps={{ disabled: initialRecord && !haveValuesChanged }}
      visible={visible}
      onCancel={onCancel}
      onOk={(e) => form.submit()}
      confirmLoading={
        updateMutationResult.loading || createMutationResult.loading
      }
    >
      <EmailTemplateForm
        form={form}
        initialEmailTemplate={initialRecord}
        initialValues={initialValues}
        updateMutation={updateMutation}
        createMutation={createMutation}
        haveValuesChanged={haveValuesChanged}
        setHaveValuesChanged={setHaveValuesChanged}
      />
    </Modal>
  );
};

export { EmailTemplateForm, EmailTemplateMutationModal };
