import React from 'react';
import { Space, Tag, Tooltip, Typography } from 'antd';
import { TagProps } from 'antd/lib/tag';
import { NetworkTagFragment } from '../../graphql/generated';

interface NetworkTagProps extends TagProps {
  network: Partial<NetworkTagFragment>;
}
const NetworkTag: React.FC<NetworkTagProps> = ({ network, ...rest }) => {
  return (
    <Tooltip
      title={
        <>
          {network.network_description && <p>{network.network_description}</p>}
          {network.network_number && (
            <p>Network Number: {network.network_number}</p>
          )}
          {network.network_id}
        </>
      }
    >
      <Tag {...rest}>
        <Space>
          {network.network_name}
          <Typography.Text type="secondary">{network.dc_id}</Typography.Text>
        </Space>
      </Tag>
    </Tooltip>
  );
};

export default NetworkTag;
