import React, { useState, useEffect } from 'react';

import { Result, Button, Table, Row, Col, Tooltip, Input } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { PaginationProps } from 'antd/lib/pagination';
import { PlusOutlined, StopOutlined, CheckOutlined } from '@ant-design/icons';

import { textToHighlight } from '../../components/utils/Highlight';
import ProviderDescription from './ProviderDescription';
import { ProviderMutationModal } from './ProviderForm';
import PracticeTag from '../practices/PracticeTag';
import NetworkTag from '../networks/NetworkTag';
import MutateButton from '../utils/MutateButton';

import useConstant from 'use-constant';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { DEBOUNCE_MS } from '../autocompletes';

import {
  ProviderDetailsFragment,
  SearchProvidersDocument,
  SearchProvidersAdvancedDocument,
  useSearchProvidersAdvancedLazyQuery,
  SearchProvidersAdvancedQueryVariables,
  UpdateProviderDocument,
  Order_By,
} from '../../graphql/generated';
import { getOperationAST } from 'graphql';

interface ProvidersTableProps {
  initialQuery?: string;
}

const ProvidersTable: React.FC<ProvidersTableProps> = ({ initialQuery }) => {
  const initialSearchParams: {
    variables: SearchProvidersAdvancedQueryVariables;
    pagination: PaginationProps;
  } = {
    variables: {
      exact_query: initialQuery || null,
      substring_query: initialQuery ? `%${initialQuery}%` : null,
      additional_filters: [{ is_archived: { _in: [false] } }],
      offset: 0,
      limit: 20,
      order_by: [{ created_at: Order_By.Desc }],
    },
    pagination: {
      current: 1,
      pageSize: 20,
    },
  };
  const [searchParams, setSearchParams] = useState(initialSearchParams);
  const [
    searchProvidersQuery,
    searchProvidersQueryResults,
  ] = useSearchProvidersAdvancedLazyQuery({
    variables: initialSearchParams.variables,
  });
  const searchProvidersDebounced = useConstant(() =>
    AwesomeDebouncePromise(
      (searchParams: SearchProvidersAdvancedQueryVariables) => {
        return searchProvidersQuery({ variables: searchParams });
      },
      DEBOUNCE_MS,
      { key: (fieldId, _) => fieldId }
    )
  );
  useEffect(() => {
    searchProvidersDebounced(searchParams.variables);
  }, [searchParams, searchProvidersDebounced]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<
    ProviderDetailsFragment | undefined
  >();

  if (searchProvidersQueryResults.error)
    return (
      <Result
        status="error"
        title="There was an error retrieving providers"
        subTitle={searchProvidersQueryResults.error?.message}
      />
    );

  const columns: ColumnsType<ProviderDetailsFragment> = [
    {
      title: 'Provider Name',
      dataIndex: 'provider_name',
      key: 'provider_name',
      sorter: true,
      render: textToHighlight(searchParams.variables.exact_query),
    },
    {
      title: 'NPI',
      dataIndex: 'provider_id',
      key: 'provider_id',
      sorter: true,
      render: textToHighlight(searchParams.variables.exact_query),
    },
    {
      title: 'Practices',
      dataIndex: 'practices',
      render: (_, record) => (
        <>
          {record.provider_employments
            .filter((e) => !e.is_archived && !e.practice.is_archived)
            .map((e) => (
              <PracticeTag practice={e.practice} key={e.practice.practice_id} />
            ))}
        </>
      ),
    },
    {
      title: 'Networks',
      dataIndex: 'networks',
      render: (_, record) => (
        <>
          {record.provider_memberships
            .filter((m) => !m.is_archived && !m.network.is_archived)
            .map((m) => (
              <NetworkTag network={m.network} key={m.network.network_id} />
            ))}
        </>
      ),
    },
    {
      title: 'Active?',
      dataIndex: 'is_archived',
      key: 'is_archived',
      width: 1,
      align: 'center',
      sorter: true,
      filters: [
        {
          text: 'Active',
          value: 'false',
        },
        {
          text: 'Archived',
          value: 'true',
        },
      ],
      defaultFilteredValue: ['false'],
      onFilter: (value, record) => record.is_archived.toString() === value,
      render: (is_archived) =>
        is_archived ? (
          <Tooltip title="Item has been archived">
            <StopOutlined className="danger-color" />
          </Tooltip>
        ) : (
          <CheckOutlined className="success-color" />
        ),
    },
    {
      key: 'edit',
      width: 1,
      align: 'center',
      render: (text, record) => {
        return record.is_archived ? (
          <MutateButton
            document={UpdateProviderDocument}
            variables={{
              provider_id: record.provider_id,
              changes: { is_archived: false },
            }}
            refetchQueries={[
              getOperationAST(SearchProvidersDocument)?.name?.value || '',
              getOperationAST(SearchProvidersAdvancedDocument)?.name?.value ||
                '',
            ]}
          >
            Restore
          </MutateButton>
        ) : (
          <div style={{ display: 'inline-flex' }}>
            <Button
              type="link"
              size="small"
              style={{ margin: 0 }}
              onClick={() => {
                setSelectedRecord(record);
                setShowUpdateModal(true);
              }}
            >
              Edit
            </Button>
            <MutateButton
              document={UpdateProviderDocument}
              variables={{
                provider_id: record.provider_id,
                changes: { is_archived: true },
              }}
              refetchQueries={[
                getOperationAST(SearchProvidersDocument)?.name?.value || '',
                getOperationAST(SearchProvidersAdvancedDocument)?.name?.value ||
                  '',
              ]}
              popconfirm={{
                title: 'Archive this item?',
              }}
            >
              Archive
            </MutateButton>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Row gutter={[8, 8]} justify="center">
        <Col flex="auto">
          <Input.Search
            defaultValue={initialQuery || undefined}
            placeholder="Search by provider name or npi"
            enterButton
            allowClear
            loading={searchProvidersQueryResults.loading}
            onSearch={(value) => {
              const trimmedValue = value;
              const newParams = {
                exact_query: trimmedValue,
                substring_query: trimmedValue ? `%${trimmedValue}%` : null,
                offset: 0,
              };
              setSearchParams((old) => {
                return {
                  variables: { ...old.variables, ...newParams },
                  pagination: { ...old.pagination, current: 1 },
                };
              });
            }}
            onChange={(e) => {
              const trimmedValue = e.target.value.trim();
              const newParams = {
                exact_query: trimmedValue,
                substring_query: trimmedValue ? `%${trimmedValue}%` : null,
                offset: 0,
              };
              setSearchParams((old) => {
                return {
                  variables: { ...old.variables, ...newParams },
                  pagination: { ...old.pagination, current: 1 },
                };
              });
            }}
          />
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setShowCreateModal(true)}
          >
            Add Provider
          </Button>
          <ProviderMutationModal
            formName="Create"
            visible={showCreateModal}
            onCancel={() => setShowCreateModal(false)}
            afterSubmit={() => setShowCreateModal(false)}
          />
          <ProviderMutationModal
            formName="Update"
            initialRecord={selectedRecord}
            visible={showUpdateModal}
            onCancel={() => setShowUpdateModal(false)}
            afterSubmit={() => setShowUpdateModal(false)}
          />
        </Col>
      </Row>
      <Row>
        <Col flex="auto">
          <Table<ProviderDetailsFragment>
            size="small"
            rowKey="provider_id"
            loading={searchProvidersQueryResults.loading}
            dataSource={searchProvidersQueryResults.data?.providers}
            pagination={{
              ...searchParams.pagination,
              position: ['bottomRight'],
              showSizeChanger: true,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              total:
                searchProvidersQueryResults.data?.providers_aggregate.aggregate
                  ?.count || 0,
            }}
            columns={columns}
            showSorterTooltip={false}
            onChange={(pagination, filters, sorter, extra) => {
              if (extra.action === 'sort') {
                const new_order = !sorter
                  ? []
                  : (sorter instanceof Array ? sorter : [sorter])
                      .filter((c) => c.columnKey && c.order)
                      .map((column) => {
                        const key = column.columnKey?.toString() || '';
                        const order =
                          column.order === 'ascend'
                            ? Order_By.AscNullsFirst
                            : Order_By.DescNullsLast;
                        return { [key]: order };
                      });
                const newParams = {
                  order_by: !new_order.length
                    ? initialSearchParams.variables.order_by
                    : new_order,
                };
                setSearchParams((old) => {
                  return {
                    variables: { ...old.variables, ...newParams },
                    pagination: old.pagination,
                  };
                });
              } else if (extra.action === 'filter') {
                const additional_filters: any[] = [];
                for (const k in filters) {
                  if (filters[k]?.length) {
                    additional_filters.push({ [k]: { _in: filters[k] } });
                  }
                }
                const newParams = {
                  offset: 0,
                  additional_filters: additional_filters,
                };
                setSearchParams((old) => {
                  return {
                    variables: { ...old.variables, ...newParams },
                    pagination: { ...old.pagination, current: 1 },
                  };
                });
              } else if (extra.action === 'paginate') {
                const newParams = {
                  limit: pagination.pageSize,
                  offset:
                    (pagination.pageSize || 0) *
                    ((pagination.current || 0) - 1),
                };
                setSearchParams((old) => {
                  return {
                    variables: { ...old.variables, ...newParams },
                    pagination: {
                      ...old.pagination,
                      current: pagination.current || 1,
                      pageSize: pagination.pageSize || old.pagination.pageSize,
                    },
                  };
                });
              }
            }}
            expandable={{
              expandedRowRender: (record) => (
                <ProviderDescription provider={record} />
              ),
            }}
          ></Table>
        </Col>
      </Row>
    </>
  );
};

export default ProvidersTable;
